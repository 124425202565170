import { Button, Col, Row, Modal, Form, Alert } from "react-bootstrap"
import { useState } from "react"
import PartecipanteService from "../../../services/PartecipanteService";
import { CgDanger } from "react-icons/cg";

export default function AggiungiAdminModal(props) {
    const [erroriCompilazione, setErroriCompilazione] = useState({ dparCf: "", dparCognome: "", dparDataNascita: "", dparEmail: "", dparNome: "", dparPhoneNumber: "" })
    let [esitoRegistrazione, setEsitoRegistrazione] = useState("")
     let [datiAdmin, setDatiAdmin] = useState({ dparCf: "", dparCognome: "", dparDataNascita: "", dparEmail: "", dparNome: "", dparPhoneNumber: "" })
    const today = new Date().toISOString().split('T')[0];

    const handleClose = () => {
        setEsitoRegistrazione("")
        setDatiAdmin({ dparCf: "", dparCognome: "", dparDataNascita: "", dparEmail: "", dparNome: "", dparPhoneNumber: ""})
        props.setShow(false)
    }

    const handleChange = (name, value) => {
        setDatiAdmin(prevState => ({
            ...prevState,
            [name]: value
        }));
        valorizzaErrore(name, "")
    };

    const valorizzaErrore = (name, value) => {
        setErroriCompilazione(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const aggiungiAdmin = () => {
        setErroriCompilazione({ dparCf: "", dparCognome: "", dparDataNascita: "", dparEmail: "", dparNome: "", dparPhoneNumber: "" })
        setEsitoRegistrazione("")
        //controllo del form
        let errori = false
        const regex = /^[A-Z0-9]{16}$/;
        const regexTelefono = /^39\d{8,12}$/; // solamente per numeri italiani

        Object.entries(datiAdmin).forEach(dato => {
            var [chiave, val] = dato
            if (!val) {
                errori = true
                valorizzaErrore(chiave, "Necessario inserire il valore")
            }
            if (chiave === 'dparCf' && !regex.test(val)) {
                errori = true
                valorizzaErrore(chiave, "Il codice fiscale inserito risulta errato")
            }
            if(chiave === 'dparPhoneNumber' && !regexTelefono.test(val)){
                errori = true
                valorizzaErrore(chiave, "Il numero di telefono deve iniziare con '39' e contenere tra 8 e 12 cifre")
            }
        })

        if (!errori) {
            PartecipanteService.nuovoPartecipante(props.comId, datiAdmin, true)
                .then(res => {
                    if(res.status===200 && res.data===false) {
                        setEsitoRegistrazione("Dati inseriti errati o codice fiscale già registrato. Si prega di verificare i dati inseriti.")
                    }
                    else if (res.status === 200) {
                        props.setRicaricaPartecipanti(true)
                        handleClose()
                        props.setTitoloToast("Registrazione Admin")
                        props.setMessaggioToast("La registrazione dell'admin é stata eseguita con successo!")
                        props.setShowToast(true)
                    }
                    else
                        setEsitoRegistrazione("Qualcosa é andato storto nella registrazione dell'admin. Si prega di riprovare piú tardi.")
                })
                .catch(err =>
                    setEsitoRegistrazione("Qualcosa é andato storto nella registrazione dell'admin. Si prega di riprovare piú tardi.")
                )
        }
    }

    return (
        <Modal show={props.show} onHide={handleClose}
            centered size="lg"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Registra un nuovo admin:</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {esitoRegistrazione &&
                    <Row>
                        <Alert variant='danger'>
                            <CgDanger className="mr-1 mb-1" /> {esitoRegistrazione}
                        </Alert>
                    </Row>
                }
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                <strong>Nome: *</strong>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                placeholder="Inserisci il nome dell'admin"
                                value={datiAdmin.dparNome}
                                onChange={(ev) => { handleChange("dparNome", ev.target.value) }}
                            />
                            {erroriCompilazione.dparNome &&
                                <Form.Label className="mt-1 text-danger d-block">
                                    <strong>
                                        {erroriCompilazione.dparNome}
                                    </strong>
                                </Form.Label>
                            }
                        </Form.Group>
                    </Col>
                    <Col  xs={12} md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label >
                                <strong>Cognome: *</strong>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                placeholder="Inserisci il cognome dell'admin"
                                value={datiAdmin.dparCognome}
                                onChange={(ev) => { handleChange("dparCognome", ev.target.value) }}
                            />
                            {erroriCompilazione.dparCognome &&
                                <Form.Label className="mt-1 text-danger d-block">
                                    <strong>
                                        {erroriCompilazione.dparCognome}
                                    </strong>
                                </Form.Label>
                            }
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col  xs={12} md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label >
                                <strong>Codice fiscale: *</strong>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                placeholder="Inserisci il codice fiscale dell'admin"
                                value={datiAdmin.dparCf}
                                onChange={(ev) => { handleChange("dparCf", ev.target.value.toUpperCase()) }}
                            />
                            {erroriCompilazione.dparCf &&
                                <Form.Label className="mt-1 text-danger d-block">
                                    <strong>
                                        {erroriCompilazione.dparCf}
                                    </strong>
                                </Form.Label>
                            }
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" >
                            <Form.Label>
                                <strong>Data di nascita: * </strong>
                            </Form.Label>
                            <Form.Control
                                type="date"
                                required
                                placeholder="Inserisci la data di nascita dell'admin"
                                value={datiAdmin.dparDataNascita}
                                onChange={(ev) => { handleChange("dparDataNascita", ev.target.value) }}
                                max={today}
                            />
                            {erroriCompilazione.dparDataNascita &&
                                <Form.Label className="mt-1 text-danger d-block">
                                    <strong>
                                        {erroriCompilazione.dparDataNascita}
                                    </strong>
                                </Form.Label>
                            }
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                <strong>Numero di telefono: *</strong>
                            </Form.Label>
                            <Form.Label column sm="12">
                                <i className="small text-muted">L'admin ricevera un messaggio durante l'accesso per l'autenticazione a due fattori</i>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                placeholder="Es. 393282828281"
                                value={datiAdmin.dparPhoneNumber}
                                onChange={(ev) => { handleChange("dparPhoneNumber", ev.target.value) }}
                            />
                            {erroriCompilazione.dparEmail &&
                                <Form.Label className="mt-1 text-danger d-block">
                                    <strong>
                                        {erroriCompilazione.dparEmail}
                                    </strong>
                                </Form.Label>
                            }
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label column sm="4">
                                <strong>Email: *</strong>
                            </Form.Label>
                            <Form.Label column sm="12">
                                <i className="small text-muted">L'admin ricevera un link per abilitare il suo accesso sulla piattaforma</i>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                placeholder="Inserisci l'email dell'admin"
                                value={datiAdmin.dparEmail}
                                onChange={(ev) => { handleChange("dparEmail", ev.target.value) }}
                            />
                            {erroriCompilazione.dparEmail &&
                                <Form.Label className="mt-1 text-danger d-block">
                                    <strong>
                                        {erroriCompilazione.dparEmail}
                                    </strong>
                                </Form.Label>
                            }
                        </Form.Group>
                    </Col>
                </Row>

            </Modal.Body>

            <Modal.Footer className="justify-content-between">
                <Button variant="outline-primary"
                    onClick={handleClose}>Chiudi</Button>
                <Button variant="primary"
                    onClick={aggiungiAdmin}>Registra admin</Button>
            </Modal.Footer>
        </Modal>
    )
}