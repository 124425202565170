import { useNavigate, useLocation } from "react-router-dom";
import { Button, Form, Container, Card, Alert, Row, Col } from "react-bootstrap";
import { useState, useRef } from "react";
import PartecipanteService from "../../../services/PartecipanteService";
import login from '../../../immagini/login/login.png'
import logo_eleweb_partecipa from '../../../immagini/login/logo_eleweb_partecipa.png'
import { AiOutlineHome } from 'react-icons/ai'
import { versione } from "../../Utilities/variables";
import { useEffect } from "react";

var bcrypt = require('bcryptjs');

export default function Login(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef(null);

  const [email, setEmail] = useState("");
  // eslint-disable-next-line
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const [prevPath, setPrevPath] = useState("/")
  const [petId, setPetId] = useState(0)
  const [refId, setRefId] = useState(0)
  const [bilId, setBilId] = useState(0)
  const [proId, setProId] = useState(0)

  //Nuovo stato per la MFA
  const [OTPformVisible, setOTPformVisible] = useState(false);
  const [otp, setOtp] = useState("");
  //Sarebbe meglio mettere il valore nell'env: REACT_APP_TTL_OTP=300
  const [countdown, setCountdown] = useState(300);
  const [isResending, setIsResending] = useState(false);
  const [utente, setUtente] = useState(null);
  const [verifyId, setVerifyId] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  //per fare hash della password
  async function hashPassword(password) {
    //FIXME: sale randomico
    return await bcrypt.hash(password, '$2a$10$lo6MtehiUNWnrUsytjrveu');
  }

  async function handleSubmit() {
    const form = ref.current
    if (!isValidEmail(email)) {
      setError('Formato email non valido');
    } else {
      setError(null);
    }

    if (form.checkValidity() === true) {
      let hash = await hashPassword(password)
      PartecipanteService.login(email, hash, props.comune.comId)
        .then((res) => {
          //console.log("res.data", res.data)
          if(res.data.esito>=0){
            if(res.data.esito===1){
              if(res.data.token){
                sessionStorage.setItem('partecipante', JSON.stringify(btoa(res.data.partecipante.datiPartecipante.dparCf)))
                sessionStorage.setItem('token', btoa(res.data.token))
                props.aggiornaRicaricaPartecipante()
                navigate(-1)
              }else{
                setError('Impossibile effettuare il login');
              }
            }else if(res.data.esito === 3){
              //CHECK se corretto verifyId o mettere verify_id
              setVerifyId(res.data.verifyId);
              setOTPformVisible(true);
              //CHECK COSa c'è effettivamente dentro partecipante o se serve datiPartecipante
              setUtente(res.data.partecipante);
            }else{
              setError('Impossibile effettuare il login');

            }
          }else{
            if(res.data.esito===-100){
              setError('Credenziali errate');
            }else if(res.data.esito===-101){
              setError("utente non autorizzato per questo comune");
            }else if(res.data.esito===-102){
              setError("Accesso consentito solo ai residenti di questo comune");
            }
          }
          /*
          if (res.data.partecipante.parId) {
            sessionStorage.setItem('partecipante', JSON.stringify(btoa(res.data.partecipante.datiPartecipante.dparCf)))
            sessionStorage.setItem('token', btoa(res.data.token))
            props.aggiornaRicaricaPartecipante()
            navigate(-1)
          } else setError('Impossibile effettuare il login');*/
        })
        .catch((error) => {
          console.log(error.response.data)
          if(error.response.data.esito===-100){
            setError('Credenziali errate');
          }else if(error.response.data.esito===-101){
            setError("utente non autorizzato per questo comune");
          }else if(error.response.data.esito===-102){
            setError("Accesso consentito solo ai residenti di questo comune");
          }else{
            setError('Impossibile effettuare il login');
            console.log(error)
          }
          
        })

      // if ((props.partecipante && props.partecipante.ruolo
      //   && props.partecipante.ruolo.ruoFlAmministratore)) {
      //   //caso 3
      //   goToPath("/admin/cruscotto")
      // } else {
      // }
    }
  }

  useEffect(() => {
    if (location.state) {
      setPrevPath(location.state.prevPath)
      setPetId(location.state.petId)
      setRefId(location.state.refId)
      setBilId(location.state.bilId)
      setProId(location.state.proId)
    }
  }, [location.state])


  const handleLoginSpid = () => {
    window.location.href = props.comune.comSpidLoginSite;
  }

    //FUNZIONI OTPFORM
    useEffect(() => {
      if(OTPformVisible){
        if (countdown > 0) {
          const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
          return () => clearTimeout(timer);
        } 
      }
      }, [countdown,OTPformVisible]);
    
      
    const handleResendSms = () => {
      setIsResending(true);
      setError(null);
      PartecipanteService.getNewOtp(utente.parId).then((res) => {
        if(res.data.esito>0){
          setIsResending(false);
          setCountdown(300);
          setVerifyId(res.data.verifyId);
        }
        else{
          setError("Errore nell'invio del nuovo codice");
        }
      })
    
    };

    const handleOtpSubmit = (event) => {
      event.preventDefault();
      //console.log("utente.parId", utente)

    const form = ref.current
    if(otp.trim().length===6){
      setError("Inserisci un OTP valido!");
    }
      const params={
        "code":otp,
        "verifyId":verifyId
      };
      PartecipanteService.verifyOTP(utente.parId, params).then((res) => {
        if(res.data.esito>0){
          sessionStorage.setItem('partecipante', JSON.stringify(btoa(res.data.partecipante.datiPartecipante.dparCf)));
          sessionStorage.setItem('token', btoa(res.data.token));
          props.aggiornaRicaricaPartecipante();
          navigate("/", { state: { prevPath: prevPath } });

        }
        else{
          setError("Errore nella verifica del nuovo codice");
        }
      })
      .catch((err) => setError("Errore nella verifica del nuovo codice"));
    
    };

  return (
    <>
      <Container fluid="md">
        <Card className="mt-2 pt-3 m-0" style={{ border: "none" }}>
          <Row className="w-100 mt-4 pt-0 m-0 ">
            <Col sm={6} className="d-none d-sm-flex align-items-center " >
              <Row>
                <a href='/' className="mb-5 pb-4 ms-4" style={{ color: "grey", textDecorationLine: "none" }}>
                  <AiOutlineHome style={{ fontSize: "30px" }} ></AiOutlineHome> Torna alla pagina iniziale  </a>
                <img src={login} style={{ width: "100%" }} alt="Homepage" />
                <h3 className="title pt-5 pb-4 ms-4">Partecipa alla gestione del tuo Comune</h3>

                <span className=" d-flex ms-4 text-justify">Segnala problemi, ricervi aggiornamenti e consulta le informazioni: con e-Partecipa collabori e hai un canale di comunicazione diretto con il Comune.
                  Gestire un territorio non è compito semplice, ma con volontà, senso civico e un pò di tecnologia possiamo tutti fare la nostra parte.

                </span>
              </Row>
            </Col>
            {!OTPformVisible && <Col sm={6} className="d-flex align-items-center p-0 ">
              <Container fluid="xs">
                <Form ref={ref}>
                  <Row className="mb-5 pb-5 ms-4">
                    <img src={logo_eleweb_partecipa} style={{ width: "30%" }} alt="Logo eleweb partecipa" />
                  </Row>
                  <Col className="w-100 mb-5 pb-3 pt-0 ">
                    <Card.Title className="mx-5 mb-1">
                      <h3 className="title">Accedi alla piattaforma</h3>
                    </Card.Title>

                    {props.comune.comSpidLoginSite &&
                      <>
                        <Row className='smallText pt-4 pb-2 px-5 ms-0 text-center w-100'>
                          <Button
                            variant="primary" style={{ borderRadius: "17px" }} onClick={handleLoginSpid}>
                            Accedi con SPID/CIE
                          </Button>
                        </Row>

                        <Row className='smallText pt-4 mb-3 ms-0 text-center d-flex justify-content-center align-items-center w-100'>
                          <div className="divider" style={{ height: "0", width: "25%" }} />
                          <span className="w-25">oppure </span>
                          <div className="divider" style={{ height: "0", width: "25%" }} />
                        </Row>
                      </>
                        }
                    <Card.Title className="mx-5">
                      <span className="text-muted smallText text-justify" style={{ fontSize: "0.8em" }}>
                      Se sei un amministratore o se il tuo comune ti ha autorizzato ad accedere tramite email e password, inserisci qui le tue credenziali.
                        </span>
                    </Card.Title>

                    <Form.Group className="px-5 mt-5" controlId="formBasicEmail"  >
                      <Form.Control required type="email" style={{ borderRadius: "17px" }} placeholder="Email *"
                        value={email}
                        onChange={(ev) => { setEmail(ev.target.value) }} />
                      <Form.Text className="text-muted">
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="px-5 mt-3 mb-1" controlId="formBasicPassword"   >
                      <Form.Control required type="password" style={{ borderRadius: "17px" }}
                        placeholder="Password *"
                        value={password}
                        onChange={(ev) => { setPassword(ev.target.value) }}
                      />
                    </Form.Group>
                    <Row className='smallText pt-4 pb-2 px-5 ms-0 text-center w-100'>
                      <Button
                        variant="primary" style={{ borderRadius: "17px" }} onClick={handleSubmit}>
                        Accedi
                      </Button>
                    </Row>
                    
                    {error && <Row><Col><Alert className="my-1" variant="danger" > {error}</Alert></Col></Row>}
                  </Col>
                </Form>
                <Container >
                  <Card.Body className="mb-3">
                    <Row className='smallText pt-5 text-center w-100 ms-2'>
                      <Col sm={6}>
                        <a href='https://www.pro-logic.it/it/home/' style={{ textDecorationLine: "none", color: "grey" }}> ® Copyright Pro Logic Informatica - wwww.pro-logic.it</a>
                      </Col>
                      {/* <Col sm={2} style={{ color: "grey" }}> {versione}
                      </Col> */}

                      <Col sm={3}>
                        <a href='https://www.eleweb.it/ ' style={{ textDecorationLine: "none", color: "grey" }} > © eleweb, 2022, {versione}</a>
                      </Col>
                      <Col >
                        <a href='https://www.eleweb.it/' style={{ textDecorationLine: "underline", color: "grey" }}> Privacy Policy & GDPR </a>
                      </Col>
                    </Row>
                  </Card.Body>
                </Container>
              </Container>
            </Col>
            }
            {OTPformVisible && <Col sm={6} className="d-flex align-items-center p-0 ">
              <Container fluid="xs">
                <Form ref={ref}>
                  <Row className="mb-5 pb-5 ms-4">
                    <img src={logo_eleweb_partecipa} style={{ width: "30%" }} alt="Logo Eleweb Partecipa" />
                  </Row>
                  <Col className="w-100 mb-5 pb-3 pt-0 ">
                    <Card.Title className="mx-5 mb-1">
                      <h3 className="title">Verifica OTP</h3>
                    </Card.Title>
                    <Card.Title className="mx-5">
                      <span className="text-muted smallText text-justify" style={{ fontSize: "0.8em" }}>
                      Inserisci il codice ricevuto per messaggio.
                        </span>
                    </Card.Title>
                    <Row className="mb-3">
                    <Col xs={12} className="px-5">

                    <Form.Group controlId="formOTP"   >
                      <Form.Control required type="text" style={{ borderRadius: "17px" }}
                        placeholder="Inserisci OTP *"
                        value={otp}
                        onChange={(ev) => { setOtp(ev.target.value) }}
                      />
                    </Form.Group>
                    </Col>
                    </Row>

                    <Row className="mb-3">
                    <Col xs={12} className="px-5">

                    {countdown > 0 ? (
                          <span className="text-muted">Valido per {countdown} secondi</span>
                        ) : (
                          <span className="text-danger">OTP scaduto</span>
                        )}
                      
                    </Col>
                    </Row>
                    <Row className='smallText pt-4 pb-2 px-5 ms-0 text-center w-100'>
                      <Button
                        variant="primary" style={{ borderRadius: "17px" }} onClick={handleOtpSubmit} type="submit">
                        Verifica OTP
                      </Button>
                    </Row>
                    <Row className='smallText pt-4 pb-2 px-5 ms-0 text-center w-100'>
                      <Button
                        variant="primary" style={{ borderRadius: "17px" }} onClick={handleResendSms} type="button">
                        Invia nuovo OTP
                      </Button>
                    </Row>
                    
                    {error && <Row><Col><Alert className="my-1" variant="danger" > {error}</Alert></Col></Row>}
                  </Col>
                </Form>
                <Container >
                  <Card.Body className="mb-3">
                    <Row className='smallText pt-5 text-center w-100 ms-2'>
                      <Col sm={6}>
                        <a href='https://www.pro-logic.it/it/home/' style={{ textDecorationLine: "none", color: "grey" }}> ® Copyright Pro Logic Informatica - wwww.pro-logic.it</a>
                      </Col>
                      {/* <Col sm={2} style={{ color: "grey" }}> {versione}
                      </Col> */}

                      <Col sm={3}>
                        <a href='https://www.eleweb.it/ ' style={{ textDecorationLine: "none", color: "grey" }} > © eleweb, 2022, {versione}</a>
                      </Col>
                      <Col >
                        <a href='https://www.eleweb.it/' style={{ textDecorationLine: "underline", color: "grey" }}> Privacy Policy & GDPR </a>
                      </Col>
                    </Row>
                  </Card.Body>
                </Container>
              </Container>
              
            </Col>}
          </Row >
        </Card >
      </Container >
    </>
  );
}


