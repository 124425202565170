import dayjs from "dayjs"
import { statiBilancio, statiIniziativa, statiPetizione, statiReferendum } from "./statiElementEnum"

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)


// funzione per cambiare stato SE è una petizione
function getStatusPetizione(petizione, comune) {
    let stato = petizione.petStato
    if (comune) {
        if ( petizione.petStato === statiPetizione.InCorso && petizione.firmePetizione &&
            (petizione.firmePetizione.length + petizione.petFirmeOffline) >= comune.impostazioniComune.imcSogliaPetizioni
        )
            stato = statiPetizione.InAttesa

        if (petizione.petStato === statiPetizione.InCorso && dayjs().isAfter(dayjs(petizione.petFineRaccoltaFirme))
        ) {
            if (petizione.firmePetizione &&
                (petizione.firmePetizione.length + petizione.petFirmeOffline) >= comune.impostazioniComune.imcSogliaPetizioni
            )
                stato = statiPetizione.InAttesa
            else
                stato = statiPetizione.FirmeNonRaggiunte
        }
    }
    return stato
}

// funzione per cambiare stato  SE è un referendum
function getStatusReferendum(referendum, comune) {
    let stato = referendum.refStato

    if (comune && referendum.refStato === statiReferendum.InCorso) {
        let soglie = comune.impostazioniComune.soglie
        let firmeAttuali = referendum.firmeReferendum && (referendum.firmeReferendum.length + parseInt(referendum.refFirmeOffline))
        let lastSoglia
        let firmeRichieste
        console.log("firmeAttuali", firmeAttuali)
        if (referendum.soglieReferendum && referendum.soglieReferendum.length > 0) {
            console.log("soglie referendum", referendum.soglieReferendum)
            let lastSogliaReferendum = referendum.soglieReferendum[referendum.soglieReferendum.length - 1]
            console.log("lastSogliaReferendum", lastSogliaReferendum)

            lastSoglia = soglie.filter(soglia => (soglia.sogOrdine === (lastSogliaReferendum.soglia.sogOrdine + 1) && soglia.sogActive === true))[0]
            console.log("lastSoglia", lastSoglia)

        } else if (soglie && soglie.length > 0){
            console.log("soglie", soglie)
           // lastSoglia = soglie[0]
            lastSoglia = soglie.find(soglia => soglia.sogActive === true && soglia.sogOrdine === Math.min(...soglie.filter(s => s.sogActive).map(s => s.sogOrdine)));

            console.log("lastSoglia", lastSoglia)
        }
        firmeRichieste = lastSoglia && lastSoglia.sogValore

        if (firmeAttuali >= (firmeRichieste))
            stato = statiReferendum.InAttesa
    }

    if (dayjs().isAfter(dayjs(referendum.refFineRaccoltaFirme)) && referendum.refStato === statiReferendum.InCorso)
        stato = statiReferendum.FirmeNonRaggiunte

    return stato
}

// funzione per cambiare stato SE è un bilancio
function getStatusBilancio(bilancio) {
    let stato = bilancio.bilStato

    if (dayjs().isAfter(dayjs(bilancio.bilDataChiusura)) && bilancio.bilStato === statiBilancio.InCorso)
        stato = statiBilancio.Concluso

    return stato
}

// funzione per cambiare stato SE è un bilancio
function getStatusIniziativa(iniziativa) {
    let stato = iniziativa.iniStato

    if (dayjs().isAfter(dayjs(iniziativa.iniDataChiusura)) && iniziativa.iniStato === statiIniziativa.InCorso)
        stato = statiIniziativa.Conclusa

    return stato
}


export {
    getStatusPetizione,
    getStatusReferendum,
    getStatusBilancio,getStatusIniziativa
}