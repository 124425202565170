import { Card, Container, Row, Col, ProgressBar, Button, Badge, ListGroup } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import NumberFormat from "react-number-format"
import { firmaPetizione } from "../../../utilities/icone";
import { BsPersonCircle } from 'react-icons/bs'
import { ImCalendar } from 'react-icons/im'
import { MdOutlineCategory } from 'react-icons/md'
import { GrStatusInfo } from 'react-icons/gr'
import { FaRegDotCircle } from 'react-icons/fa'
import { FiChevronLeft } from 'react-icons/fi'
import { BiEraser } from "react-icons/bi"
import ReferendaService from "../../../services/ReferendaService";
import FirmeReferendumService from "../../../services/FirmeReferendumService";
import ModalConferma from "../../Utilities/ModalConferma";
import ModalPermessi from "../../Utilities/ModalPermessi";
import { getReferendumColor } from "../../Utilities/getBorderColor";
import { getStatusReferendum } from '../../Utilities/getStatus'
import { Navigate } from 'react-router-dom';

import dayjs from "dayjs";
import { statiReferendum } from "../../Utilities/statiElementEnum";

export default function PaginaReferendum(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const [referendum, setReferendum] = useState({})

    //props.ricaricaReferendum è utilizzato per ricaricare il referendum dopo il voto o una firma
    //questo stato invece serve a caricarlo la prima vota che è visitata la pagina
    const [caricaReferendum, setCaricaReferendum] = useState(true)
    
    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);

    // stati per aprire i modali
    const [mostraModaleFirma, setMostraModaleFirma] = useState(false)
    const [mostraModaleRevoca, setMostraModaleRevoca] = useState(false)
    const [mostraModaleConferma, setMostraModaleConferma] = useState(false)
    const [mostraModaleVoto, setMostraModaleVoto] = useState(false)

    const [firmaEspressa, setFirmaEspressa] = useState(false)
    
    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    //percentuale di completamento da mostrare sulla progress bar
    function getProgressBarValue() {
        if (referendum.firmeReferendum) {
            return (referendum.firmeReferendum.length + referendum.refFirmeOffline) * 100 / mostraSoglia()
        }
    }

    //funzione che sceglie la giusta soglia da mostrare
    function mostraSoglia() {
        //per aspettare che il referendum sia caricato, usiamo il primo if
        if (referendum.soglieReferendum) {
            let soglieAttive = props.comune.impostazioniComune.soglie
                    .filter(s => s.sogActive) // Prendo solo le soglie attive
                    .sort((a,b) => a.sogOrdine - b.sogOrdine); //Ordino le soglie per ordine crescente
            
            let statoReferendum = referendum.refStato; //Stato attuale del referendum
            if (referendum.soglieReferendum.length === 0) {
                //il referendum non ha superato alcuna soglia, quindi scrivo la prima
                return soglieAttive.length > 0 ? soglieAttive[0].sogValore : null;
            } else {
                //il referendum ha superato alcune soglie, quindi prendo la prossima, se esiste
                // Prendo l'ultima soglia approvata
            let ultimaSogliaApprovata = referendum.soglieReferendum
            .map(s => s.soglia)
            .sort((a, b) => a.sogOrdine - b.sogOrdine) // Ordino per ordine crescente
            .pop(); // Prendo l'ultima soglia approvata
        
            // Se il referendum è concluso o in attesa di ammissione, mostro l'ultima soglia approvata
            if (statoReferendum === "Concluso" || statoReferendum === "Firme raggiunte" ) {
                return ultimaSogliaApprovata.sogValore;
            }// Altrimenti, cerco la prossima soglia disponibile
        let prossimaSoglia = soglieAttive.find(s => s.sogOrdine === ultimaSogliaApprovata.sogOrdine + 1);

        // Se esiste una prossima soglia, la ritorno, altrimenti ritorno l'ultima approvata
        return prossimaSoglia ? prossimaSoglia.sogValore : ultimaSogliaApprovata.sogValore;
            }
        }

    }

    //numero di firme che mancano alla soglia
    const getFirmeMancanti = () => {
        return mostraSoglia() - (referendum.firmeReferendum.length + referendum.refFirmeOffline)
    }

    const handleFirma = () => {
        const firma = {
            firData: dayjs().format('YYYY-MM-DD'),
            referendum: referendum,
            partecipante: props.partecipante
        }
        FirmeReferendumService.registraFirma(firma)
            .then(() => {
                props.setRicaricaReferendum(true)
                setMostraModaleConferma(true)
            })
            .catch(() => {
                //TODO: gestione dell'errore
            })
            .finally(() => {
                setMostraModaleFirma(false)
            })
    }

    const handleRevoca = () => {
        const firma = {
            firId: referendum.firmeReferendum.filter(firma => firma.partecipante.parId === props.partecipante.parId)[0].firId,
            firData: dayjs(referendum.firmeReferendum.filter(firma => firma.partecipante.parId === props.partecipante.parId)[0].firData).format('YYYY-MM-DD'),
            partecipante: props.partecipante,
            referendum: referendum
        }
        FirmeReferendumService.revocaFirma(firma)
            .then(() => {
                props.setRicaricaReferendum(true)
                setFirmaEspressa(false)
            })
            .catch((err) => {
                console.log(err)
                //TODO: gestione dell'errore
            })
            .finally(() => {
                setMostraModaleRevoca(false)
            })
    }

    //fetch referendum
    useEffect(() => {
        if (props.ricaricaReferendum || caricaReferendum) {
            if (location.state && location.state.refId) {
                ReferendaService.getReferendum(location.state.refId)
                    .then((res) => {
                        setReferendum({...res.data, refStato: getStatusReferendum(res.data, props.comune)})
                        //controllo se ha già firmato
                        if (res.data.firmeReferendum &&
                            res.data.firmeReferendum.filter(firma => firma.partecipante.parId === props.partecipante.parId).length > 0) {
                            setFirmaEspressa(true)
                        } else {
                            setFirmaEspressa(false)
                        }
                        //controllo se ha già votato
                        // if (res.data.votantiReferendum &&
                        //     res.data.votantiReferendum.filter(voto => voto.partecipante.parId == props.partecipante.parId).length > 0) {
                        //     setVotoEspresso(true)
                        // } else {
                        //     setVotoEspresso(false)
                        // }
                    })
                    .catch((err) => {
                        setReferendum({})
                        //TODO gestione dell'errore
                    })
                props.setRicaricaReferendum(false)
                setCaricaReferendum(false)
            }
        }
    }, [location.state, props, caricaReferendum])

    const IsLogged = () => {
        // 1. non loggato => vai a login
        // 2. no permessi => avviso
        // 3. tutto ok
        if (props.partecipante && props.partecipante.parId) {
            if (props.partecipante.ruolo && props.partecipante.ruolo.ruoFlVotanteReferendum && props.partecipante.ruolo.ruoFlVisualizzatore) {
                //caso 3
                setMostraModaleFirma(true)
            } else {
                // caso 2
                setTitolo("Attenzione: operazione illecita")
                setMessaggio("Attualmente non possiedi i permessi per firmare un referendum. L'operazione è bloccata!")
                setMode("danger")
                setMostraModalPermessi(true)
            }
        } else {
            // caso 1
            setTitolo("Attenzione")
            setMessaggio("Per procedere con la firma di un referendum devi prima effettuare il login!")
            setMode("warning")
            setMostraModalPermessi(true)
        }
    }

    const permettiFirma = () => {
        return dayjs().isBefore(dayjs(referendum.refFineRaccoltaFirme))
    }

    if (props.comune && props.comune.impostazioniComune) {
        if (
            props.comune.impostazioniComune.imcModuloReferendumAttivo &&
            location.state &&
            location.state.refId
        ) {
            return (
                <>
                    <div className="pt-4 px-md-5 pb-2  px-2 bg-secondary">
                        <Row className="w-100 p-0 m-0">
                            <Col xs={12} md={7} className="p-0 align-self-center">
                                <span className="smallcaps dark">Referendum</span>
                                <h1 className="title dark">{referendum.refTitolo}</h1>
                                <h5><Badge bg="primary">{referendum.refTipo}</Badge></h5>
                            </Col>
                            <Col xs={12} md={7} className="p-0">
                                <Card className="text-center cartaSommario"
                                    border={getReferendumColor(referendum)}
                                >
                                    <Card.Body>
                                        <h3 className="smallcaps title m-0">{referendum.refStato}</h3>
                                        <Row>
                                            <span>{dayjs(referendum.refInizioRaccoltaFirme).format('DD/MM/YYYY')} - {dayjs(referendum.refFineRaccoltaFirme).format('DD/MM/YYYY')}</span>
                                        </Row>
                                        <Row>
                                            <span className="mt-3">
                                                <strong className="bigText">
                                                    <NumberFormat
                                                        displayType="text"
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        value={referendum.firmeReferendum && referendum.firmeReferendum.length + referendum.refFirmeOffline}
                                                    />
                                                </strong>/
                                                <NumberFormat
                                                    displayType="text"
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    value={mostraSoglia()}
                                                />
                                            </span>
                                        </Row>
                                        <Container className="mb-3">
                                            <ProgressBar variant="progressBar" now={getProgressBarValue()} />
                                        </Container>
                                        {!firmaEspressa && referendum.refStato !== statiReferendum.Concluso && referendum.refStato !== statiReferendum.FirmeRaggiunte &&
                                            <Row>
                                                <div>
                                                    <Button className="w-20"
                                                        onClick={IsLogged}
                                                        disabled={!permettiFirma()}>
                                                        Firma il referendum {firmaPetizione}</Button>
                                                </div>
                                            </Row>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "50vh" }}>
                        <Card.Header />
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={7}>
                                    <p className="text-center">
                                        <BsPersonCircle className="me-2 m-auto" />
                                        {referendum.partecipante && referendum.partecipante.datiPartecipante &&
                                            referendum.partecipante.datiPartecipante.dparNome} {' '}
                                        {referendum.partecipante && referendum.partecipante.datiPartecipante &&
                                            referendum.partecipante.datiPartecipante.dparCognome} {' '}
                                        {referendum.refAssociazione ? `per ${referendum.refAssociazione}` : ""}
                                    </p>
                                    <Card>
                                        <Card.Header>{referendum.refTitolo}</Card.Header>
                                        <Card.Body>
                                            {referendum.refDescrizione && referendum.refDescrizione.split("\n").map((val, index) => (
                                                <span key={index}>{val} <br /></span>
                                            ))}
                                        </Card.Body>

                                    </Card>
                                </Col>
                                <Col xs={{ span: 9, offset: 1 }} md={{ span: 4, offset: 1 }} className="text-center">
                                    <Row>
                                        <Col xs={{ span: 9, offset: 2 }} md={{ span: 7, offset: 1 }} className="text-center">
                                            <ListGroup variant="flush" >
                                                &nbsp;
                                                <span >Raccolta firme &nbsp;<ImCalendar /></span>
                                                <ListGroup.Item><span> dal <strong>{dayjs(referendum.refInizioRaccoltaFirme).format('DD/MM/YYYY')}</strong></span>
                                                    <br />
                                                    <p> al <strong>{dayjs(referendum.refFineRaccoltaFirme).format('DD/MM/YYYY')}</strong></p></ListGroup.Item>
                                                <ListGroup.Item> <p>{referendum.refTipo}
                                                    &nbsp;
                                                    <MdOutlineCategory /></p></ListGroup.Item>
                                                <ListGroup.Item> <p>{referendum.refStato}
                                                    &nbsp;
                                                    <GrStatusInfo />
                                                </p></ListGroup.Item>
                                                <ListGroup.Item>{referendum.refStato === statiReferendum.InCorso && referendum.refStato !== statiReferendum.Concluso && referendum.refStato !== statiReferendum.FirmeRaggiunte &&
                                                    <>
                                                        <p>Mancano ancora <strong>
                                                            <NumberFormat
                                                                displayType="text"
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                value={getFirmeMancanti()}
                                                            />
                                                        </strong> firme
                                                            &nbsp;
                                                            <FaRegDotCircle /></p>
                                                    </>
                                                }
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {referendum.soglieReferendum && referendum.soglieReferendum.length !== 0 &&
                                <Col className="mt-5">
                                    {referendum.soglieReferendum.map((soglia, index) => (
                                        <p key={index} className="text-start mb-2">Il referendum è stato dichiarato <strong>ammissibile</strong> in data <strong>{dayjs(soglia.sorData).format('DD/MM/YYYY')}</strong> da <strong>{soglia.sorEnteApprovante}</strong></p>
                                    ))}
                                </Col>
                            }
                            <Col className=" mt-5 text-center">
                                {referendum.refStato === statiReferendum.Concluso &&
                                    // se il referendum è concluso mostro l'esito
                                    <>
                                        <div className="divider" />
                                        <h2 className="title2 text-start mb-3">Esito consultazione</h2>
                                        <p className="text-start">Pubblicata in data {dayjs(referendum.refDataEsito).format('DD/MM/YYYY')}</p>
                                        <Card>
                                            <Card.Body className="text-justify">
                                                {referendum.refEsitoConsultazione && referendum.refEsitoConsultazione.split("\n").map((val, index) => (
                                                    <span key={index}>{val} <br /></span>
                                                ))}
                                            </Card.Body>
                                        </Card>
                                    </>
                                }
                                {referendum.refStato !== statiReferendum.Concluso && referendum.refStato !== statiReferendum.FirmeRaggiunte &&
                                    // se il referendum non è concluso e non ho firmato, mostro il pulsante per firmare
                                    !firmaEspressa &&
                                    <>
                                        <p>Questo referendum ti ha convinto?</p>
                                        <Button
                                            onClick={IsLogged}
                                            disabled={!permettiFirma()}>
                                            Firma adesso!
                                            <span className="m-2">{firmaPetizione}</span>
                                        </Button>
                                    </>
                                }
                                {referendum.refStato !== statiReferendum.Concluso  && referendum.refStato !== statiReferendum.FirmeRaggiunte  && firmaEspressa &&
                                    // se il referendum non è concluso e ho firmato, mostro questi dati
                                    <>
                                        <p>Hai <strong>firmato</strong> questo referendum in data <strong>{dayjs(referendum.firmeReferendum.filter(firma => firma.partecipante.parId === props.partecipante.parId)[0].data).format('DD/MM/YYYY')}</strong></p>

                                        <Button variant="outline-danger"
                                            onClick={() => { setMostraModaleRevoca(true) }}>
                                            Revoca la firma
                                            <BiEraser className="ms-2" size={25} />
                                        </Button>
                                    </>
                                }
                                {/* {referendum.refStato === statiReferendum.FirmeRaggiunte &&

                                    //se il referendum è stato firme raggiunte, mostro il pulsante per votare
                                    <>
                                        <div className="divider" />
                                        <Button className="mt-3" onClick={() => { setMostraModaleVoto(true) }}>
                                            Vota adesso!
                                            <span className="m-2">{votaReferendum}</span>
                                        </Button>
                                    </>
                                } */}
                            </Col>
                        </Card.Body>
                        <Card.Footer />
                    </Card>
                    <Button variant="outline-dark" className="ms-5 mb-3"
                        onClick={() => { navigate('/esplora-referendum') }}>
                        <FiChevronLeft />
                        Torna a tutti i referendum</Button>

                    <ModalConferma
                        mostra={mostraModaleFirma}
                        setMostra={setMostraModaleFirma}
                        titolo="Firma il referendum"
                        messaggio={`Stai per firmare il referendum "${referendum.refTitolo}". Sei sicuro?`}
                        btnAnnulla="No, annulla"
                        btnConferma="Si, firma"
                        mode="continua"
                        azioneAnnulla={() => { setMostraModaleFirma(false) }}
                        azioneConferma={() => { handleFirma() }}
                    />

                    <ModalConferma
                        mostra={mostraModaleConferma}
                        setMostra={setMostraModaleConferma}
                        titolo="Firmato con successo!"
                        messaggio={`Il referendum è stato firmato con successo!`}
                        btnConferma="Chiudi"
                        mode="success"
                        azioneConferma={() => { setMostraModaleConferma(false) }}
                    />

                    <ModalConferma
                        mostra={mostraModaleRevoca}
                        setMostra={setMostraModaleRevoca}
                        titolo="Revoca la firma"
                        messaggio={`Stai per revocare la firma che hai espresso per il referendum "${referendum.refTitolo}". Sei sicuro?`}
                        btnAnnulla="No, non revocare"
                        btnConferma="Si, revoca firma"
                        mode="danger"
                        azioneAnnulla={() => { setMostraModaleRevoca(false) }}
                        azioneConferma={() => { handleRevoca() }}
                    />

                    <ModalConferma
                        mostra={mostraModaleVoto}
                        setMostra={setMostraModaleVoto}
                        titolo="Vota il quesito referendario"
                        messaggio="Stai per votare il quesito referendario. Questa operazione non è reversibile. Sei sicuro?"
                        btnAnnulla="No, annulla"
                        btnConferma="Si, vai al voto"
                        mode="continua"
                        azioneAnnulla={() => { setMostraModaleVoto(false) }}
                        azioneConferma={() => {
                            window.open(`/referendum/voto/${referendum.refId}`, '_blank', 'popup')
                            setMostraModaleVoto(false)
                        }}
                    />

                    <ModalPermessi
                        mostra={mostraModalPermessi}
                        setMostra={setMostraModalPermessi}
                        titolo={titolo}
                        messaggio={messaggio}
                        mode={mode}
                    />
                </>
            )
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }

} 