////////////////////////////////////////////////////////////////////////////////////////
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Form, Container, Card, Alert, Row, Col } from "react-bootstrap";
import { useState, useRef } from "react";
import PartecipanteService from "../../services/PartecipanteService";
import login from '../../immagini/login/login.png'
import logo_eleweb_partecipa from '../../immagini/login/logo_eleweb_partecipa.png'
import { versione } from "../Utilities/variables";
import { useEffect } from "react";

var bcrypt = require('bcryptjs');

export default function Login(props) {
  const navigate = useNavigate();
  const ref = useRef(null);
  // eslint-disable-next-line
  const [password, setPassword] = useState("");
  const [confermaPassword, setConfermaPassword] = useState("");
  const [error, setError] = useState(null);

    //Nuovo stato per la MFA
    const [OTPformVisible, setOTPformVisible] = useState(false);
    const [otp, setOtp] = useState("");
    //Sarebbe meglio mettere il valore nell'env: REACT_APP_TTL_OTP=300
    const [countdown, setCountdown] = useState(300);
    const [isResending, setIsResending] = useState(false);
    const [utente, setUtente] = useState(null);
    const [verifyId, setVerifyId] = useState(null);

    const [prevPath, setPrevPath] = useState("/")


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const token = query.get("token");
  const [partecipante, setPartecipante] = useState(false);

  useEffect(() => {
    PartecipanteService.findByTokenAccesso(token).then((response) => {
      if (response.status === 200 && response.data !== "") {
        setPartecipante(response.data);
      } else if (response.status === 200 && response.data === "") {
        navigate("/pagina-non-trovata");
      }
      else {
        navigate("/pagina-non-trovata");
      }
    })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);


  
  async function hashPassword(password) {
    //FIXME: sale randomico
    return await bcrypt.hash(password, '$2a$10$lo6MtehiUNWnrUsytjrveu');
  }
  

  /*async function hashPassword(password) {
    const salt = await bcrypt.genSalt(10); // Genera un salt randomico
    const hashedPassword = await bcrypt.hash(password, salt);
    return hashedPassword;
  }*/

  async function handleSubmit() {
    const form = ref.current
    if (form.checkValidity() === true) {
      let hashPwd = await hashPassword(password)
      let hashConfermaPwd = await hashPassword(confermaPassword)
      if (hashPwd !== hashConfermaPwd) {
        setError('Le password non corrispondono');
        return
      }
      PartecipanteService.cambiaPassword(token, hashPwd, hashConfermaPwd)
        .then((res) => {
          console.log("res cambia password: ", res)
          if (res.status === 200) {
            if(res.data.esito === 1){
              if(res.data.token){
              sessionStorage.setItem('partecipante', JSON.stringify(btoa(res.data.partecipante.datiPartecipante.dparCf)))
              sessionStorage.setItem('token', btoa(res.data.token))
              props.aggiornaRicaricaPartecipante()
             navigate("/");
              }else{
                setError("Impossibile effetture il login");
              }
            }else if(res.data.esito === 3){
              //MFA richiesto
              setVerifyId(res.data.verifyId);
              setOTPformVisible(true);
              setUtente(res.data.partecipante);
            }else if(res.data.esito === -100){
              //password mismatch
              setError("Le due password non coincidono");
            } else if(res.data.esito === -101){
              setError("Partecipante non autorizzato o telefono mancante");

            }else{
              //Errore generico
              setError("Errore durante la modifica della password");
            }
            
            
          }
          else setError('Impossibile effettuare il login');
        }
        )
        .catch((error) => {
          setError("Si è verificato un errore, per favore riprova più tardi.");
        })
    }
  }

     
  const handleResendSms = () => {
    setIsResending(true);
    setError(null);
    PartecipanteService.getNewOtp(utente.parId).then((res) => {
      if(res.data.esito>0){
        setIsResending(false);
        setCountdown(300);
        setVerifyId(res.data.verifyId);
      }
      else{
        setError("Errore nell'invio del nuovo codice");
      }
    })
  
  };

      //FUNZIONI OTPFORM
      useEffect(() => {
        if(OTPformVisible){
          if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
          } 
        }
        }, [countdown,OTPformVisible]);

  const handleOtpSubmit = (event) => {
    event.preventDefault();
    console.log("utente.parId", utente)

  const form = ref.current
  if(otp.trim().length===6){
    setError("Inserisci un OTP valido!");
  }
    const params={
      "code":otp,
      "verifyId":verifyId
    };
    PartecipanteService.verifyOTP(utente.parId, params).then((res) => {
      if(res.data.esito>0){
        sessionStorage.setItem('partecipante', JSON.stringify(btoa(res.data.partecipante.datiPartecipante.dparCf)));
        sessionStorage.setItem('token', btoa(res.data.token));
        props.aggiornaRicaricaPartecipante();
        navigate("/", { state: { prevPath: prevPath } });

      }
      else{
        setError("Errore nella verifica del nuovo codice");
      }
    })
    .catch((err) => setError("Errore nella verifica del nuovo codice"));
  
  };




  return (
    <>
      <Container fluid="md">
        <Card className="mt-2 pt-3 m-0" style={{ border: "none" }}>
          <Row className="w-100 mt-4 pt-0 m-0 ">
            <Col sm={6} className="d-none d-sm-flex align-items-center " >
              <Row>
                <img src={login} style={{ width: "100%" }} alt="Homepage" />
                <h3 className="title pt-5 pb-4 ms-4">Partecipa alla gestione del tuo Comune</h3>

                <span className=" d-flex ms-4 text-justify">Segnala problemi, ricervi aggiornamenti e consulta le informazioni: con e-Partecipa collabori e hai un canale di comunicazione diretto con il Comune.
                  Gestire un territorio non è compito semplice, ma con volontà, senso civico e un pò di tecnologia possiamo tutti fare la nostra parte.

                </span>
              </Row>
            </Col>
            {!OTPformVisible && <Col sm={6} className="d-flex align-items-center p-0 ">
              <Container fluid="xs">
                <Form ref={ref}>
                  <Row className="mb-5 pb-5 ms-4">
                    <img src={logo_eleweb_partecipa} style={{ width: "30%" }} alt="Logo eleweb partecipa" />
                  </Row>
                  <Col className="w-100 mb-5 pb-3 pt-0 ">
                    <Card.Title className="mx-5 mb-1">
                      <h3 className="title">Ti stavamo aspettando {partecipante ? partecipante.datiPartecipante.dparNome : ""} </h3>
                    </Card.Title>
                    <Card.Title className="mx-5">
                      <span className="text-muted smallText text-justify" style={{ fontSize: "0.8em" }}>Si prega di inserire una nuova password e di confermarla per accedere ai servizi di e-partecipa. </span>
                    </Card.Title>

                    <Form.Group className="px-5 mt-3 mb-1" controlId="formBasicPassword"   >
                      <Form.Control required type="password" style={{ borderRadius: "17px" }}
                        placeholder="Password *"
                        value={password}
                        onChange={(ev) => { setPassword(ev.target.value) }}
                      />
                    </Form.Group>
                    <Form.Group className="px-5 mt-3 mb-1" controlId="formBasicConfermaPassword"   >
                      <Form.Control required type="password" style={{ borderRadius: "17px" }}
                        placeholder="Conferma password *"
                        value={confermaPassword}
                        onChange={(ev) => { setConfermaPassword(ev.target.value) }}
                      />
                    </Form.Group>
                    <Row className='smallText pt-4 pb-2 px-5 ms-0 text-center w-100'>
                      <Button
                        variant="primary" style={{ borderRadius: "17px" }} onClick={handleSubmit}>
                        Salva password
                      </Button>
                    </Row>
                    {error && <Row><Col><Alert className="my-1" variant="danger" > {error}</Alert></Col></Row>}
                  </Col>
                </Form>
                <Container >
                  <Card.Body className="mb-3">
                    <Row className='smallText pt-5 text-center w-100 ms-2'>
                      <Col sm={6}>
                        <a href='https://www.pro-logic.it/it/home/' style={{ textDecorationLine: "none", color: "grey" }}> ® Copyright Pro Logic Informatica - wwww.pro-logic.it</a>
                      </Col>
                      {/* <Col sm={2} style={{ color: "grey" }}> {versione}
                      </Col> */}

                      <Col sm={3}>
                        <a href='https://www.eleweb.it/ ' style={{ textDecorationLine: "none", color: "grey" }} > © eleweb, 2022, {versione}</a>
                      </Col>
                      <Col >
                        <a href='https://www.eleweb.it/' style={{ textDecorationLine: "underline", color: "grey" }}> Privacy Policy & GDPR </a>
                      </Col>
                    </Row>
                  </Card.Body>
                </Container>
              </Container>
            </Col>
            }
             {OTPformVisible && <Col sm={6} className="d-flex align-items-center p-0 ">
                          <Container fluid="xs">
                            <Form ref={ref}>
                              <Row className="mb-5 pb-5 ms-4">
                                <img src={logo_eleweb_partecipa} style={{ width: "30%" }} alt="Logo Eleweb Partecipa" />
                              </Row>
                              <Col className="w-100 mb-5 pb-3 pt-0 ">
                                <Card.Title className="mx-5 mb-1">
                                  <h3 className="title">Verifica OTP</h3>
                                </Card.Title>
                                <Card.Title className="mx-5">
                                  <span className="text-muted smallText text-justify" style={{ fontSize: "0.8em" }}>
                                  Inserisci il codice ricevuto per messaggio.
                                    </span>
                                </Card.Title>
                                <Row className="mb-3">
                                <Col xs={12} className="px-5">
            
                                <Form.Group controlId="formOTP"   >
                                  <Form.Control required type="text" style={{ borderRadius: "17px" }}
                                    placeholder="Inserisci OTP *"
                                    value={otp}
                                    onChange={(ev) => { setOtp(ev.target.value) }}
                                  />
                                </Form.Group>
                                </Col>
                                </Row>
            
                                <Row className="mb-3">
                                <Col xs={12} className="px-5">
            
                                {countdown > 0 ? (
                                      <span className="text-muted">Valido per {countdown} secondi</span>
                                    ) : (
                                      <span className="text-danger">OTP scaduto</span>
                                    )}
                                  
                                </Col>
                                </Row>
                                <Row className='smallText pt-4 pb-2 px-5 ms-0 text-center w-100'>
                                  <Button
                                    variant="primary" style={{ borderRadius: "17px" }} onClick={handleOtpSubmit} type="submit">
                                    Verifica OTP
                                  </Button>
                                </Row>
                                <Row className='smallText pt-4 pb-2 px-5 ms-0 text-center w-100'>
                                  <Button
                                    variant="primary" style={{ borderRadius: "17px" }} onClick={handleResendSms} type="button">
                                    Invia nuovo OTP
                                  </Button>
                                </Row>
                                
                                {error && <Row><Col><Alert className="my-1" variant="danger" > {error}</Alert></Col></Row>}
                              </Col>
                            </Form>
                            <Container >
                              <Card.Body className="mb-3">
                                <Row className='smallText pt-5 text-center w-100 ms-2'>
                                  <Col sm={6}>
                                    <a href='https://www.pro-logic.it/it/home/' style={{ textDecorationLine: "none", color: "grey" }}> ® Copyright Pro Logic Informatica - wwww.pro-logic.it</a>
                                  </Col>
                                  {/* <Col sm={2} style={{ color: "grey" }}> {versione}
                                  </Col> */}
            
                                  <Col sm={3}>
                                    <a href='https://www.eleweb.it/ ' style={{ textDecorationLine: "none", color: "grey" }} > © eleweb, 2022, {versione}</a>
                                  </Col>
                                  <Col >
                                    <a href='https://www.eleweb.it/' style={{ textDecorationLine: "underline", color: "grey" }}> Privacy Policy & GDPR </a>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Container>
                          </Container>
                          
                        </Col>}
          </Row >
        </Card >
      </Container >
    </>
  );
}


