import { useState, useEffect } from "react";
import { Container, Form, Alert, Button } from "react-bootstrap";
import Soglie from "./ImpostazioniSoglie";
import ImpostazioniComuneService from "../../../services/ImpostazioniComuneService";
import ModalConferma from "../../Utilities/ModalConferma";
import dayjs from "dayjs";

export default function ImpostazioniReferenda(props) {
  let [soglie, setSoglie] = useState([]);
  let [showAlert, setShowAlert] = useState({ message: "", variant: "" });
  let stepSoglie = 20;

  const [mostraModaleConferma, setMostraModaleConferma] = useState(false);
  const [mostraModaleSuccesso, setMostraModaleSuccesso] = useState(false);
  const [mostraModaleErrore, setMostraModaleErrore] = useState(false);

  useEffect(() => {
    setSoglie((props.comune?.impostazioniComune?.soglie) || []);
  }, [props.comune]);

  const checkSoglie = (tempSoglie) => {
    let ret = true;
    let sogValore = 0;

    tempSoglie.forEach(soglia => {
      if (soglia.sogOrdine > 1 && soglia.sogActive && soglia.sogValore < (sogValore + stepSoglie)) {
        ret = false;
      }
      if (soglia.sogActive) {
        sogValore = soglia.sogValore;
      }
    });

    return ret;
  };

  const svuotaMessaggi = () => {
    setShowAlert({ message: "", variant: "" });
  };

  const preparaSalva = (tempSoglie) => {
    if (!checkSoglie(tempSoglie)) {
        setShowAlert({ message: "Controlla i valori delle soglie!", variant: "danger" });
    } else {
        // Disattivare tutte le soglie esistenti
        const soglieDisattivate = soglie.map(s => ({
            ...s,
            sogActive: false,
            sogDataDisattivazione: dayjs().format("YYYY-MM-DD"),
        }));

        // Creare nuove soglie solo per quelle che sono state modificate
        const nuoveSoglie = tempSoglie.map(s => ({
            sogOrdine: s.sogOrdine,
            sogValore: s.sogValore,
            sogActive: true,
            sogDataCreazione: dayjs().format("YYYY-MM-DD"), // Data creazione nuova soglia
        }));

        // Salvare sia le soglie disattivate sia le nuove
        setSoglie([...soglieDisattivate, ...nuoveSoglie]);

        setMostraModaleConferma(true);
    }
};

  const salvaImpostazioni = async () => {
    let impostazioni = {
      ...props.comune.impostazioniComune,
      soglie: soglie,
      comune: props.comune
    };

    ImpostazioniComuneService.aggiornaImpostazioni(impostazioni)
      .then(res => {
        if (res.status === 200) {
          setMostraModaleConferma(false);
          setMostraModaleSuccesso(true);
          props.setRicaricaComune(true);
          props.setMostraTabella(true);
        } else {
          setMostraModaleErrore(true);
          setMostraModaleConferma(false);
        }
      })
      .catch(err => {
        setMostraModaleConferma(false);
        setMostraModaleErrore(true);
      });
  };

  return (
    <>
      <Form>
        <Soglie
          soglie={soglie}
          setSoglie={setSoglie}
          stepSoglie={stepSoglie}
          svuotaMessaggi={svuotaMessaggi}
          preparaSalva={preparaSalva}
        />
      </Form>

      {showAlert.message && (
        <Container className="fixed-bottom w-50">
          <Alert variant={showAlert.variant}>{showAlert.message}</Alert>
        </Container>
      )}

      <ModalConferma
        mostra={mostraModaleConferma}
        setMostra={setMostraModaleConferma}
        mode="continua"
        titolo="Salvataggio impostazioni"
        messaggio="Confermi il salvataggio delle impostazioni?"
        btnConferma="Si, salva"
        btnAnnulla="No, annulla"
        azioneConferma={salvaImpostazioni}
      />
    </>
  );
}
