import React from "react"
import { useState, useEffect } from "react"
import { Container, Tab, Tabs, OverlayTrigger, Popover  } from "react-bootstrap"
import TabellaBilanci from "./TabellaBilanci"
import GestisciBilancio from "./GestisciBilancio"
import ProposteElenco from "./Proposte/ProposteElenco"
import ProposteGestisci from "./Proposte/ProposteGestisci"
import ImpostazioniBilanci from "./ImpostazioniBilanci"
import BilancioPartecipativoService from "../../../services/BilancioPartecipativoService"
import ModuloNonAttivo from "../ModuloNonAttivo";
import { useNavigate, useLocation } from "react-router-dom";
import PaginaNonTrovata from "../../Utente/Error/PaginaNonTrovata"
import { BsQuestionCircle } from "react-icons/bs"


export default function BilanciPartecipativi(props) {
    const navigate = useNavigate();
    const location = useLocation();

    // Dirty bit
    let [dirty, setDirty] = useState(true)

    // Stoppa il ricaricamento dei dati quando vengono caricati la prima volta. Viene riattivato quando c'è bisogno di caricare nuovamente i dati del db
    // (dopo) un inserimento di righe
    let [stopLoading, setStopLoading] = useState(false)

    // Fetch bilanci
    let [bilanci, setBilanci] = useState([])

    // Stato che decide come deve essere gestito il bilancio
    let [gestisci, setGestisci] = useState("")

    // Stato che decide come deve essere gestita la proposta
    let [gestisciProposta, setGestisciProposta] = useState("")

    // Stato per l'applicazione del filtro stato sui bilanci
    let [statoFilter, setStatoFilter] = useState("")

    // Stato che passa l'id del bilancio
    let [bilId, setBilId] = useState("")

    let [proposta, setProposta] = useState()

    useEffect(() => {
        if (dirty && props.comune) {
            BilancioPartecipativoService.getAllByComune(props.comune.comId)
                .then(res => {
                    setBilanci(res.data)
                })
                .then(() => {
                    setStopLoading(false)
                })
                .catch((err) => {
                    setBilanci([])
                })
                .finally(() => {
                    if (props.comune.comId)
                        setDirty(false)
                })
        }
    }, [props.comune, dirty]);

    const toggleTab = (ev) => {
        if (ev.target.dataset.rrUiEventKey === "impostazioni") {
            props.setTabAttivaBilanci("impostazioni")
            navigate("/admin/bilanci-partecipativi#impostazioni")
        }
        if (ev.target.dataset.rrUiEventKey === "bilanciPartecipativi") {
            props.setTabAttivaBilanci("bilanciPartecipativi")
            navigate("/admin/bilanci-partecipativi#tutti-i-bilanci")
        }
    }
    useEffect(() => {
        if (location.hash === "#tutti-i-bilanci") props.setTabAttivaBilanci("bilanciPartecipativi")
        if (location.hash === "#impostazioni") props.setTabAttivaBilanci("impostazioni")
    }, [props, location])


    if (props.partecipante.parId && props.partecipante.ruolo.ruoFlAmministratore) {

    return (
        <Container fluid="lg">
            <div className="d-flex justify-content-between">
                <h1 className="title">{props.comune.impostazioniComune.imcLabelModuloBilanci}</h1>
                {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloBilanciAttivo &&
                        <OverlayTrigger  trigger="click" rootClose placement="left"
                            overlay={
                                <Popover id="popover-contained" >
                                    <Popover.Header as="h3">Aiuto:</Popover.Header>
                                    <Popover.Body>
                                        Ecco tutti i {props.comune.impostazioniComune.imcLabelModuloBilanci} creati.
                                        <br />
                                        <br />
                                        Possibili <strong>stati</strong>:
                                        <ul>
                                            <li><strong>In corso</strong>: bilancio visibile ai partecipanti</li>
                                            <li><strong>Non pubblicato</strong>: bilancio non ancora completo e non visibile ai partecipanti</li>
                                            <li><strong>Concluso</strong>: bilancio che ha raggiunto la sua data di chiusura</li>
                                        </ul>
                                        <br />
                                        Possibili <strong>azioni</strong>:
                                        <ul>
                                            <li><strong>Nuovo +</strong>: per creare un nuovo bilancio</li>
                                            <li><strong>Controlla</strong>: per modificare i dati o le caratteristiche del bilancio</li>
                                            <li><strong>Proposte</strong>: per gestire le proposte inviate dai partecipanti per quel bilancio</li>
                                        </ul>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <div className="my-auto" style={{cursor:"pointer"}} >
                                <BsQuestionCircle size="2em" className="my-auto" />
                            </div>
                        </OverlayTrigger>
                    }
            </div>
            {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloBilanciAttivo ? (
                <Tabs activeKey={props.tabAttivaBilanci} id="uncontrolled-tab-example" className="mb-3"
                    onClick={toggleTab}>
                    <Tab eventKey="bilanciPartecipativi" title={`Tutti i ${props.comune.impostazioniComune.imcLabelModuloBilanci}`}>
                        {
                            props.mostraTabella ? (
                                <TabellaBilanci
                                    bilanci={bilanci}
                                    setBilId={setBilId}
                                    setMostraTabella={props.setMostraTabella}
                                    setGestisci={setGestisci}
                                    statoFilter={statoFilter}
                                    setStatoFilter={setStatoFilter}
                                    setDirty={setDirty}
                                    dirty={dirty}
                                />
                            ) : (
                                gestisci !== "proposte-elenco" &&
                                    gestisci !== "proposta-gestisci" ? (
                                    <GestisciBilancio
                                        bilanci={bilanci}
                                        comune={props.comune}
                                        bilId={bilId}
                                        setMostraTabella={props.setMostraTabella}
                                        gestisci={gestisci}
                                        setGestisci={setGestisci}
                                        setDirty={setDirty}
                                    />
                                ) : (
                                    gestisci === "proposte-elenco" ? (
                                        <ProposteElenco
                                            comune={props.comune}
                                            bilanci={bilanci}
                                            bilId={bilId}
                                            setMostraTabella={props.setMostraTabella}
                                            setGestisci={setGestisci}
                                            setGestisciProposta={setGestisciProposta}
                                            setProposta={setProposta}
                                        />
                                    ) : (
                                        gestisci === "proposta-gestisci" &&
                                        <ProposteGestisci
                                            comune={props.comune}
                                            bilanci={bilanci}
                                            bilId={bilId}
                                            setGestisci={setGestisci}
                                            gestisciProposta={gestisciProposta}
                                            proposta={proposta}
                                            setProposta={setProposta}
                                            setDirty={setDirty}
                                        />
                                    )
                                )
                            )
                        }
                    </Tab>
                    <Tab eventKey="impostazioni" title="Impostazioni">
                        <ImpostazioniBilanci
                            bilanci={bilanci}
                            stopLoading={stopLoading}
                            setStopLoading={setStopLoading}
                            setDirty={setDirty}
                            comune={props.comune}
                            setRicaricaComune={props.setRicaricaComune}
                            setMostraTabella={props.setMostraTabella}
                        />
                    </Tab>
                </Tabs>
            ) : (
                <ModuloNonAttivo moduleName="Bilanci Partecipativi" />
            )}
        </Container>
    )

}else{
    return(<PaginaNonTrovata/>)
}
}