import { Card, Form, Button, Alert, OverlayTrigger, Popover, Col } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import ModalConferma from "../../Utilities/ModalConferma";
import ReferendaService from "../../../services/ReferendaService";
import { max_char_descrizione, max_char_titolo } from "../../Utilities/variables";
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { Navigate } from 'react-router-dom';
import { FiAlertTriangle } from 'react-icons/fi'
import { statiReferendum } from "../../Utilities/statiElementEnum";

export default function CreaReferendum(props) {
    const location = useLocation()

    //riferimento al form
    const ref = useRef(null);

    const [titolo, setTitolo] = useState("")
    const [associazione, setAssociazione] = useState("")
    const [tipo, setTipo] = useState("")
    const [descrizione, setDescrizione] = useState("")
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("");

    //stati per i modali di conferma
    const [mostraModaleAnnulla, setMostraModaleAnnulla] = useState(false);
    const [mostraModaleConferma, setMostraModaleConferma] = useState(false);
    const [mostraModaleOk, setMostraModaleOk] = useState(false)

    //nuovo flag consenso privacy
    const [consensoPrivacy, setConsensoPrivacy] = useState(false)

    //stato per mostrare alert di errore
    const [messaggioAlert, setMessaggioAlert] = useState("")

    //controllo per email
    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const navigate = useNavigate();
    const goToLogin = () => {
        navigate("/login", { state: { prevPath: location.pathname } })
    }

    const tipiReferendum = ["Abrogativo", "Consultivo", "Propositivo"]

    const handleSubmit = () => {
        const form = ref.current
        setValidated(true);
        setMostraModaleConferma(false)

        if (!isValidEmail(email)) {
            setError('Formato email non valido');
        } else {
            setError(null);
        }

        if (form.checkValidity() === true) {
            let referendum = {
                refAssociazione: associazione,
                refDataInvio: dayjs().format("YYYY-MM-DD"),
                refDescrizione: descrizione,
                refStato: statiReferendum.InValidazione,
                //refTipo: tipiReferendum[tipo].toLowerCase(),
                comId: props.comune.comId,
                refTipo: tipiReferendum[tipo]
                    .toLowerCase()
                    .replace(/^\w/, c => c.toUpperCase()), // Prima lettera maiuscola, resto minuscolo
                refTitolo: titolo,
                firmeReferendum: [],
                refFirmeOffline: 0,
                partecipante: props.partecipante,
                soglieReferendum: [],
                refEmail: email,
                refFlConsensoPrivacy: consensoPrivacy,

            }
            ReferendaService.saveReferendum(referendum)
                .then(() => { setMostraModaleOk(true) })
                .catch(() => { setMessaggioAlert("Impossibile inviare il referendum.") })
        } else {
            setMessaggioAlert("Impossibile inviare il referendum.")
        }
    }

    //dismiss alert
    useEffect(() => {
        setTimeout(() => {
            setMessaggioAlert("");
        }, 3000);
    }, [messaggioAlert]);

    if (props.comune && props.comune.impostazioniComune && props.partecipante) {
        if (props.comune.impostazioniComune.imcModuloReferendumAttivo) {
            if (props.partecipante.parId && props.partecipante.ruolo) {
                return (
                    <>
                        <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
                        <h1 className="title mt-4 text-center dark">Crea il tuo referendum</h1>
                        <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "90vh" }}>
                            <Card.Header>
                                <p className="mt-2 mb-0">Compila il modulo seguente con le informazioni richieste per inviare il tuo referendum.</p>
                                <p className="italic text-muted">I campi contrassegnati con un asterisco (*) sono obbligatori.</p>
                            </Card.Header>
                            <Card.Body className="p-2 p-md-5">
                                <Form noValidate validated={validated} onSubmit={handleSubmit} ref={ref} >
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Titolo * </strong>
                                            <span className="text-muted smallText">(massimo: {max_char_titolo} caratteri)</span>
                                        </Form.Label>
                                        <Form.Control required placeholder="Inserisci il titolo"
                                            value={titolo}
                                            maxLength={max_char_titolo}
                                            onChange={(ev) => { setTitolo(ev.target.value) }} />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Associazione, Comitato</strong>
                                        </Form.Label>
                                        <Form.Control placeholder="Inserisci il nome dell'associazione o comitato"
                                            value={associazione}
                                            onChange={(ev) => { setAssociazione(ev.target.value) }} />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Tipo * </strong>
                                        </Form.Label>

                                        <Form.Control required as="select" type="select" className="mb-4"
                                            value={tipo}
                                            onChange={(ev) => { setTipo(ev.target.value) }}>
                                            <option value="" disabled hidden>Seleziona il tipo</option>
                                            {tipiReferendum.map((el, index) => (
                                                <option value={index} key={index}>{el}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Col xs={8}>
                                            <Form.Label>
                                                <OverlayTrigger placement="right" overlay={
                                                    <Popover id="popover-contained" >
                                                        <Popover.Header as="h3" >Aiuto:</Popover.Header>
                                                        <Popover.Body>
                                                            Esporre, nella maniera più precisa e puntuale possibile, il contenuto del referendum evidenziandone i punti salienti utili all’identificazione della problematica per la quale si richiede l’intervento dell’Amministrazione ovvero di eventuali proposte regolamentari.
                                                        </Popover.Body>
                                                    </Popover>
                                                }>
                                                    <strong>
                                                        <strong>Descrizione * </strong>
                                                        <span className="text-muted smallText">(massimo: {max_char_descrizione} caratteri)</span>
                                                        <AiOutlineQuestionCircle className="me-2 ms-2 mb-1" style={{ fontSize: "20px" }} />
                                                    </strong>
                                                </OverlayTrigger>
                                            </Form.Label>
                                        </Col>
                                        <Form.Control required as="textarea" placeholder="Inserisci la descrizione del referendum" rows={10}
                                            value={descrizione}
                                            maxLength={max_char_descrizione}
                                            onChange={(ev) => { setDescrizione(ev.target.value) }} />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Email di contatto * </strong>
                                            <span className="text-muted smallText">(l'email che inserirai sarà utilizzata dal Comune per contattarti se necessario)</span>
                                        </Form.Label>
                                        <Form.Control required type="email" placeholder="Inserisci la tua email"
                                            value={email}
                                            onChange={(ev) => { setEmail(ev.target.value) }} />
                                        <div>
                                            {error && <span className="text-danger smallText">{error}</span>}
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Autorizzazione al consenso sulla privacy *</strong>
                                        </Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox"
                                            required
                                            label={
                                                <>
                                                    <span className="italic d-block">Acconsento al trattamento dei miei dati personali:&nbsp;
                                                        <a href={props.comune.comLinkPrivacy}>
                                                            Informativa resa ai sensi dell'art. 13 del RGPD 2016/679
                                                        </a>
                                                        &nbsp;(Regolamento Generale Protezione Dati)
                                                    </span>
                                                </>
                                            }
                                            checked={consensoPrivacy}
                                            onChange={(ev) => {
                                                setConsensoPrivacy(ev.target.checked);
                                            }} 
                                        />
                                    </Form.Group>


                                    {messaggioAlert !== "" && <Alert variant="danger" >{messaggioAlert}</Alert>}
                                    <div className="d-flex justify-content-between">
                                        <Button variant="outline-dark"
                                            onClick={() => { setMostraModaleAnnulla(true) }}>
                                            Annulla
                                        </Button>
                                        <Button
                                            onClick={() => { setMostraModaleConferma(true) }}>
                                            Invia il referendum!
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>

                        <ModalConferma
                            mostra={mostraModaleAnnulla}
                            setMostra={setMostraModaleAnnulla}
                            titolo="Annulla creazione del referendum"
                            messaggio={`Sei sicuro di voler annullare la creazione del referendum? Così facendo perderai tutto quello che hai scritto fino ad ora!`}
                            btnAnnulla="No, desidero continuare"
                            btnConferma="Sì, desidero annullare"
                            mode="danger"
                            azioneAnnulla={() => { setMostraModaleAnnulla(false) }}
                            azioneConferma={() => { navigate(-1) }}
                        />

                        <ModalConferma
                            mostra={mostraModaleConferma}
                            setMostra={setMostraModaleConferma}
                            titolo="Invia il referendum"
                            messaggio="Stai per inviare il referendum che hai appena creato. Non sarà più possibile modificarlo. Dovrà essere approvato dal comune prima di essere pubblicato."
                            btnAnnulla="Continua a modificare"
                            btnConferma="Invia"
                            mode="continua"
                            azioneAnnulla={() => { setMostraModaleConferma(false) }}
                            azioneConferma={() => { handleSubmit() }}
                        />

                        <ModalConferma
                            mostra={mostraModaleOk}
                            setMostra={setMostraModaleOk}
                            titolo="Referendum inviato"
                            messaggio="Il referendum è stato inviato correttamente"
                            btnConferma="Torna a tutti i referendum"
                            btnAnnulla=""
                            mode="success"
                            azioneAnnulla={() => { }}
                            azioneConferma={() => { navigate(-1) }}
                        />
                    </>
                )
            } else {
                return (
                    <>
                        <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
                        <h1 className="title mt-4 text-center dark">Crea il tuo referendum</h1>
                        <Card className="me-5 mt-2 ms-5 mb-5" style={{ minHeight: "90vh" }}>
                            <Card.Header></Card.Header>
                            <Card.Body className="p-5 text-center">
                                <h2>
                                    <FiAlertTriangle className="me-2 text-warning" />
                                    Attenzione
                                </h2>
                                <br />
                                Per procedere con la creazione di un nuovo referendum devi prima effettuare il login!
                                <br /><br />
                                <Button variant="primary" onClick={goToLogin}>
                                    Accedi alla pagina di Login
                                </Button>
                            </Card.Body>
                        </Card>
                    </>
                )
            }
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
}