import { useState, useEffect } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Row, Form, Collapse, Container, Spinner } from "react-bootstrap";
import { FaRegCalendarAlt } from "react-icons/fa";
import { RiFilter3Line } from "react-icons/ri"
import { BsPlusLg } from "react-icons/bs"

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import CartaPetizione from "./CartaPetizione";
import ModalPermessi from "../../Utilities/ModalPermessi";
// import PaginationBasic from "../Homepage/Pagination";

import { getStatusOrderPetizione } from "../../Utilities/getStatusOrder";
import { getStatusPetizione } from '../../Utilities/getStatus';
import { statiPetizione } from "../../Utilities/statiElementEnum";
import PaginationBasic from "../Homepage/Pagination"

import PetizioniService from "../../../services/PetizioniService";

const dayjs = require('dayjs');

export default function EsploraPetizioni(props) {
    const navigate = useNavigate();

    const [dateRange, setDateRange] = useState([null, null]);
    const [selectValue, setSelectValue] = useState(-1)
    const [checkboxes, setCheckboxes] = useState([false, false, false, false])

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);
    //stati

    const [paginaNumero, setPaginaNumero] = useState(0)
    const elementiPerPagina = 5

    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    //filtri
    let [statoFilter, setStatoFilter] = useState([])
    let [categoriaFilter, setCategoriaFilter] = useState("")

    const [open, setOpen] = useState(false);

    let [listaPetizioni, setListaPetizioni] = useState([])
    let [loadingPet, setLoadingPet] = useState(true)


    // const [paginaNumero, setPaginaNumero] = useState(0)
    // const petPagina = 5
    // const paginaVisitata = paginaNumero * petPagina

    const pulisciFiltri = () => {
        setDateRange([null, null])
        setSelectValue(-1)
        setCheckboxes([false, false, false])
        setStatoFilter([])
        setCategoriaFilter([])
        setPaginaNumero(0); // Resetto alla prima pagina
    }

    const handleDateRangeChange = (update) => {
        if (update === null) {
            setDateRange([null, null])
        } else {
            setDateRange(update);
        }
        setPaginaNumero(0); // Resetto alla prima pagina

    }

    //filtri
    const getPetizioni = () => {
        let ret = listaPetizioni
            .map(pet => { return { ...pet, petStato: getStatusPetizione(pet, props.comune) } })
            .filter(petizione => petizione.petStato !== statiPetizione.InValidazione && petizione.petStato !== statiPetizione.Rifiutata)

        if (statoFilter.length > 0) {
            ret = ret.filter(pet => statoFilter.includes(pet.petStato))
        }

        if (categoriaFilter.length > 0)
            ret = ret.filter((pet) => pet.petCategoria === categoriaFilter)

        if (dateRange[0]) {
            let startDay = dayjs(dateRange[0])
            ret = ret.filter((pet) => (dayjs(pet.petDataPubblicazione).isAfter(startDay)) || dayjs(pet.petDataPubblicazione).isSame(startDay))
        }

        if (dateRange[1]) {
            let endDay = dayjs(dateRange[1])
            ret = ret.filter((pet) => (dayjs(pet.petDataPubblicazione).isBefore(endDay)) || dayjs(pet.petDataPubblicazione).isSame(endDay))
        }

        return ret
    }

    useEffect(() => {
        PetizioniService.findByComune(props.comune.comId)
            .then(res => {
                // LISTA_VISUA = sistema stati (RES.DATA) => SOLO PER VISUALIZZAZIONE
                setListaPetizioni(res.data)
            })
            .catch(() => { setListaPetizioni([]) })
            .finally(() => { setLoadingPet(false) })
    }, [props.comune.comId]);


    // filtri

    let filterByStato = (stato, checked) => {
        if (checked === true) {
            if (statoFilter.filter(statoItem => statoItem === stato).length === 0)
                setStatoFilter(old => [...old, stato])
        } else {
            setStatoFilter(statoFilter.filter(statoItem => statoItem !== stato))
        }

        setPaginaNumero(0); // Resetto alla prima pagina

    }

    let filterByCategoria = (categoria) => {
        setCategoriaFilter(categoria)
        setPaginaNumero(0); // Resetto alla prima pagina

    }

    const IsLogged = () => {
        // 1. non loggato => vai a login
        // 2. no permessi => avviso
        // 3. tutto ok
        if (props.partecipante && props.partecipante.parId) {
            if (props.partecipante.ruolo && props.partecipante.ruolo.ruoFlVisualizzatore && props.partecipante.ruolo.ruoFlPubblicatorePetizioni) {
                //caso 3
                navigate("/crea-petizione")
            } else {
                // caso 2
                setTitolo("Attenzione: operazione illecita")
                setMessaggio("Attualmente non possiedi i permessi per creare una petizione. L'operazione è bloccata!")
                setMode("danger")
            }
        } else {
            // caso 1
            setTitolo("Attenzione")
            setMessaggio("Per procedere con la creazione di una nuova petizione devi prima effettuare il login!")
            setMode("warning")
        }
        setMostraModalPermessi(true)
    }


    const handleCheckbox = (index) => {
        let tmp = checkboxes
        tmp[index] = !tmp[index]
        setCheckboxes(tmp)
    }

    if (props.comune && props.comune.impostazioniComune) {
        if (props.comune.impostazioniComune.imcModuloPetizioniAttivo) {
            return (
                <>
                    <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
                    <h1 className="title mt-4 text-center dark">Petizioni</h1>
                    <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "100vh" }}>
                        <Card.Body className="p-0 m-0">
                            {/* FILTRI A SCHERMO PICCOLO */}
                            <Row className="d-md-none w-100 p-0 m-0">
                                <Col xs={12} sm={6} className="p-1">
                                    <Button className="w-100 mb-2" onClick={IsLogged}>
                                        Crea la tua petizione
                                        <BsPlusLg className="ms-2" />
                                    </Button>
                                </Col>
                                <Col xs={12} sm={6} className="p-1">
                                    <Button className="w-100" onClick={() => setOpen(!open)} >
                                        <RiFilter3Line className="me-2" />
                                        Mostra filtri
                                    </Button>
                                </Col>
                                <Collapse in={open} className="border-bottom pb-3">
                                    <div id="example-collapse-text">
                                        <h4 className="mt-4">Filtra per:</h4>
                                        <div className="divider my-4" />
                                        <Row>
                                            <Col>
                                                <p >Categoria</p>
                                                <Form.Select size="sm" className="mb-4"
                                                    value={selectValue}
                                                    onChange={(ev) => {
                                                        filterByCategoria(ev.target.value);
                                                        setSelectValue(ev.target.value)
                                                    }}>
                                                    <option value="-1" disabled hidden>Seleziona la categoria</option>
                                                    {[...new Set([
                                                        ...listaPetizioni.map(p => p.petCategoria), // Categorie delle petizioni
                                                        ...(props.comune?.impostazioniComune?.imcCategoriePetizioni?.categorie_petizioni || []) // Categorie dalle impostazioni del comune, con controllo per undefined
                                                    ])]
                                                        .sort()
                                                        .map((el, index) => (
                                                            <option value={el} key={index}>{el}</option>
                                                        ))}
                                                </Form.Select>
                                            </Col>
                                            <Col>
                                                <p >Stato</p>
                                                <Form.Group>
                                                    {Object.values(statiPetizione)
                                                        .filter(tipo => ![statiPetizione.InValidazione, statiPetizione.Rifiutata].includes(tipo))
                                                        .map((stato, index) => (
                                                            <Form.Check
                                                                key={index}
                                                                type="checkbox"
                                                                label={stato}
                                                                name={stato}
                                                                checked={checkboxes[index]}
                                                                onChange={(e) => {
                                                                    filterByStato(e.target.name, e.target.checked)
                                                                    handleCheckbox(index)
                                                                }}
                                                            />
                                                        )
                                                        )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p >Data pubblicazione &nbsp;
                                                    <span className="small text-muted ">(Da ... a)</span>
                                                </p>
                                                <div>
                                                    <DateRangePicker className="w-100"
                                                        id="datePickerPetizioni"
                                                        onChange={(update) => { handleDateRangeChange(update) }}
                                                        value={dateRange}
                                                        returnValue="range"
                                                        calendarIcon={<FaRegCalendarAlt style={{ height: '1em', width: '1em' }} />}
                                                        dayPlaceholder="gg"
                                                        monthPlaceholder='mm'
                                                        yearPlaceholder='aaaa'
                                                        yearAriaLabel="year"
                                                        format='dd/MM/y'
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="d-flex justify-content-center mt-4">
                                            <Button size="sm" variant="outline-primary"
                                                onClick={() => {
                                                    pulisciFiltri()
                                                }}>
                                                Pulisci filtri di ricerca
                                            </Button>
                                        </div>

                                    </div>
                                </Collapse>
                            </Row>

                            <Row className="w-100 m-0 p-0" style={{ minHeight: "100vh" }}>
                                {/* FILTRI A SCHERMO GRANDE*/}
                                <Col md={3} className="bg-light d-none d-md-block">
                                    <div className="d-flex justify-content-center">
                                        <Button className="my-3"
                                            onClick={IsLogged}>
                                            Crea la tua petizione
                                            <BsPlusLg className="ms-2" />
                                        </Button>
                                    </div>
                                    <h4 className="mt-4">Filtra per:</h4>
                                    <div className="divider my-4" />
                                    <p >Categoria</p>
                                    <Form.Select
                                        size="sm"
                                        className="mb-4"
                                        value={selectValue}
                                        onChange={(ev) => {
                                            filterByCategoria(ev.target.value);
                                            setSelectValue(ev.target.value);
                                        }}
                                    >
                                        <option value="-1" disabled hidden>Seleziona la categoria</option>
                                        {[...new Set([
                                            ...listaPetizioni.map(p => p.petCategoria), // Categorie delle petizioni
                                            ...(props.comune?.impostazioniComune?.imcCategoriePetizioni?.categorie_petizioni || []) // Categorie dalle impostazioni del comune, con controllo per undefined
                                        ])]
                                            .sort()
                                            .map((el, index) => (
                                                <option value={el} key={index}>{el}</option>
                                            ))}
                                    </Form.Select>

                                    <div className="divider my-4" />
                                    <p >Stato</p>
                                    <Form.Group>
                                        {Object.values(statiPetizione)
                                            .filter(tipo => ![statiPetizione.InValidazione, statiPetizione.Rifiutata].includes(tipo))
                                            .map((stato, index) => (
                                                <Form.Check
                                                    key={index}
                                                    type="checkbox"
                                                    label={stato}
                                                    name={stato}
                                                    checked={checkboxes[index]}
                                                    onChange={(e) => {
                                                        filterByStato(e.target.name, e.target.checked)
                                                        handleCheckbox(index)
                                                    }}
                                                />
                                            )
                                            )}
                                    </Form.Group>

                                    <div className="divider my-4" />
                                    <p >Data pubblicazione &nbsp;
                                        <span className="small text-muted ">(Da ... a)</span>
                                    </p>
                                    <div>
                                        <DateRangePicker className="w-100"
                                            id="datePickerPetizioni"
                                            onChange={(update) => { handleDateRangeChange(update) }}
                                            value={dateRange}
                                            returnValue="range"
                                            calendarIcon={<FaRegCalendarAlt style={{ height: '1em', width: '1em' }} />}
                                            dayPlaceholder="gg"
                                            monthPlaceholder='mm'
                                            yearPlaceholder='aaaa'
                                            yearAriaLabel="year"
                                            format='dd/MM/y'
                                        />
                                    </div>
                                    <div className="divider my-4" />
                                    <div className="d-flex justify-content-center">
                                        <Button size="sm" variant="outline-primary"
                                            onClick={() => { pulisciFiltri() }}>
                                            Pulisci filtri di ricerca
                                        </Button>
                                    </div>

                                </Col>

                                {/* TUTTE LE PETIZIONI */}
                                <Col md={9} xs>
                                    {loadingPet ?
                                        <Container className="text-center mt-5">
                                            <Spinner animation="border" role="status" />
                                        </Container>
                                        :
                                        <>
                                            {listaPetizioni.length > 0 &&
                                                getPetizioni()
                                                    .sort(function (a, b) {
                                                        return getStatusOrderPetizione(a.petStato) - getStatusOrderPetizione(b.petStato)
                                                    })
                                                    .slice(paginaNumero * elementiPerPagina, (paginaNumero +1) * elementiPerPagina)
                                                    // .slice(paginaVisitata, paginaVisitata + petPagina)
                                                    .map((petizione, index) => (
                                                        <CartaPetizione
                                                            key={index}
                                                            petizione={petizione}
                                                            comune={props.comune}
                                                            partecipante={props.partecipante}
                                                        />
                                                    ))
                                            }
                                            {
                                                getPetizioni().length > 0 ? (
                                                    <PaginationBasic
                                                activepage={paginaNumero}
                                                setActivePage={setPaginaNumero}
                                                numItems={getPetizioni().length} //getPetizioni() e non listaPetizioni perchè se no non prende correttamente gli elementi filtrati e mette quindi più pagine
                                                itemsPerPage={elementiPerPagina}
                                            />
                                                ) : null
                                            }
                                            
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <ModalPermessi
                        mostra={mostraModalPermessi}
                        setMostra={setMostraModalPermessi}
                        titolo={titolo}
                        messaggio={messaggio}
                        mode={mode}
                    />
                </>
            )
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
}
