import Toast from 'react-bootstrap/Toast'
import { Container, Tabs, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import PartecipanteService from "../../../services/PartecipanteService";
import ToastContainer from 'react-bootstrap/ToastContainer';
import GestioneAdmin from './GestioneAdmin';
import GestionePartecipanti from './GestionePartecipanti';
import PaginaNonTrovata from '../../Utente/Error/PaginaNonTrovata';

export default function Partecipanti(props) {
    const [partecipanti, setPartecipanti] = useState([])

    const [showToast, setShowToast] = useState(false);
    const [titoloToast, setTitoloToast] = useState("");
    const [messaggioToast, setMessaggioToast] = useState("");

    const [ricaricaPartecipanti, setRicaricaPartecipanti] = useState(true)

    useEffect(() => {
        if (ricaricaPartecipanti && props.comune.comId) {
            PartecipanteService.findByComune(props.comune.comId)
                .then(res => {
                    setPartecipanti(res.data)
                })
                .catch(err => {
                    setPartecipanti([])
                })
                .finally(()=>{
                    setRicaricaPartecipanti(false)
                })
        }
    }, [props.comune.comId, ricaricaPartecipanti])

    if (props.partecipante.parId && props.partecipante.ruolo.ruoFlAmministratore) {

    return (
        <Container fluid="lg" className="mb-5">
            <div className="d-flex justify-content-between">
                <h1 className="title">Partecipanti</h1>
            </div>
            <Tabs defaultActiveKey="partecipanti" className="mb-3">
                <Tab eventKey="admin" title="Impostazioni Admin">
                    <GestioneAdmin
                        partecipanti={partecipanti.filter(el=>el.ruolo.ruoFlAmministratore)
                            .filter(el => el.datiPartecipante.dparNome !== "-" && el.datiPartecipante.dparCognome !== "-") // Rimuove i partecipanti anonimi

                        }
                        setShowToast={setShowToast}
                        setMessaggioToast={setMessaggioToast}
                        setTitoloToast={setTitoloToast}
                        setRicaricaPartecipanti={setRicaricaPartecipanti}
                        comId={props.comune.comId} 
                        impostazioni={props.comune.impostazioniComune}/>
                </Tab>
                <Tab eventKey="partecipanti" title="Impostazioni partecipanti">
                    <GestionePartecipanti
                        comId={props.comune.comId}
                        setShowToast={setShowToast}
                        setMessaggioToast={setMessaggioToast}
                        setTitoloToast={setTitoloToast}
                        setRicaricaPartecipanti={setRicaricaPartecipanti}
                        partecipanti={partecipanti.filter(el=>!el.ruolo.ruoFlAmministratore)                     
                            .filter(el => el.datiPartecipante.dparNome !== "-" && el.datiPartecipante.dparCognome !== "-") // Rimuove i partecipanti anonimi
                    }
                        impostazioni={props.comune.impostazioniComune}
                    />
                </Tab>
            </Tabs>
            <ToastContainer style={{
                position: 'sticky',
                bottom: '20px'
            }} >
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg="success">
                    <Toast.Header>
                        <strong className="me-auto">{titoloToast}</strong>
                    </Toast.Header>
                    <Toast.Body>{messaggioToast}</Toast.Body>
                </Toast>
            </ToastContainer>
        </Container >
    )
}else{
    return(<PaginaNonTrovata/>)
}
}