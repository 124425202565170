import { Card, Form, Row, Col, Button, Alert, ButtonGroup } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import ModalConferma from "../../Utilities/ModalConferma";
import { FiUpload } from 'react-icons/fi'
import Multiselect from 'multiselect-react-dropdown';
import { BsFileEarmarkRichtext } from "react-icons/bs"
import { GoTrash } from "react-icons/go"
import ProgettoService from "../../../services/ProgettoService";
import { max_char_descrizione, max_dimensione_files } from "../../Utilities/variables";
import { Navigate } from 'react-router-dom';
import { FiAlertTriangle } from 'react-icons/fi'
import { statiProgetto } from "../../Utilities/statiElementEnum";
import MappaEIndirizzi from "../../Utilities/MappaEIndirizzi";

export default function CreaProgetto(props) {

    let ref = useRef(null);
    let inputFile = useRef(null)
    let select = useRef(null)
    const location = useLocation();
    const acceptedExtensions = ["image/*", "application/pdf",  "text/plain", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "image/png", "image/jpg", "image/jpeg"]

    const [titolo, setTitolo] = useState("")
    const [associazione, setAssociazione] = useState("")
    const [copresentatori, setCopresentatori] = useState("")
    const [posizione, setPosizione] = useState(props.comune.impostazioniComune.imcCentroMappa.centro_mappa)
    const [indirizzo, setIndirizzo] = useState("")
    const [area, setArea] = useState("")
    const [descrizione1, setDescrizione1] = useState("")
    const [descrizione2, setDescrizione2] = useState("")
    const [descrizione3, setDescrizione3] = useState("")
    const [descrizione4, setDescrizione4] = useState("")
    const [options, setOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [email, setEmail] = useState("")
    const [consensoPrivacy, setConsensoPrivacy] = useState(false)

    const [validated, setValidated] = useState(false);

    //stati per i modali di conferma
    const [mostraModaleAnnulla, setMostraModaleAnnulla] = useState(false);
    const [mostraModaleConferma, setMostraModaleConferma] = useState(false);
    const [mostraModaleOk, setMostraModaleOk] = useState(false)

    //stato per mostrare alert di errore
    const [messaggioAlert, setMessaggioAlert] = useState("")

    //stato per i file caricati, metadata e arrayBuffer
    const [uploadedFilesMetadata, setUploadedFilesMetadata] = useState([])

    //controllo per email
    const [error, setError] = useState(null);

    function isValidEmail() {
        return /\S+@\S+\.\S+/.test(email);
    }

    const navigate = useNavigate();
    const goToLogin = () => {
        navigate("/login", { state: { prevPath: location.pathname } })
    }


    const handleSubmit = () => {
        const form = ref
        setValidated(true);
        setMostraModaleConferma(false)
        setError("")
        console.log(location.state.iniziativa)

        const multiselect = select
        multiselect.searchWrapper.current.classList.remove("invalid", "border", "border-success")
        let isValid = isValidEmail()


        if(multiselect.getSelectedItemsCount() !== 0 ){
            select.searchWrapper.current.classList.add("border")
            select.searchWrapper.current.classList.add("border-success")
        }

        if (form.checkValidity() === true && multiselect.getSelectedItemsCount() !== 0 && isValid) {
            let progetto = {
                iniziativa:{iniId:location.state.iniziativa},
                progTitolo: titolo,
                partecipante: props.partecipante,
                progCategorie: { categorie: selectedOptions.map(el => el.name) },
                progArea: props.comune.impostazioniComune.imcAreeUrbane.aree_urbane[area],
                progDataInvio: dayjs().format("YYYY-MM-DD"),
                progAssociazione: associazione,
                progCopresentatori: copresentatori,
                progStato: statiProgetto.InValidazione,
                progEmail: email,
                progDescrizione1: descrizione1,
                progDescrizione2: descrizione2,
                progDescrizione3: descrizione3,
                progDescrizione4: descrizione4,
                progFlConsensoPrivacy: consensoPrivacy,
                posizione: {
                    comune: { ...props.comune, "comBannerFile": null, "comStemmaFile":null},
                    posLatitudine: posizione[0],
                    posLongitudine: posizione[1],
                    posNome: indirizzo,
                },
            }

            if(indirizzo.length===0){
                progetto.posizione={}
            }
            var formData = new FormData()
            formData.append("progetto", JSON.stringify(progetto))
            uploadedFilesMetadata.forEach(el => {
                formData.append("file", el)
            })

            ProgettoService.creaProgetto(formData)
                .then(() => {
                    setMostraModaleConferma(false)
                    setMostraModaleOk(true)
                })
                .catch((err) => {
                    setMessaggioAlert("Impossibile inviare il progetto")
                })
        } else {
            if(!isValid){
                setError("L'email inserita non è valida.")
            }
            if(multiselect.getSelectedItemsCount() === 0){
                multiselect.searchWrapper.current.classList.add("invalid")
            }
            setMessaggioAlert("Compilare i campi obbligatori. Impossibile inviare il progetto.")
        }
    }


    const handleSubmitNuovoTipoProgetto = () => {
        const form = ref;
        setValidated(true);
        setMostraModaleConferma(false);
        setError("");
    
        const isValid = isValidEmail();
    
        if (form.checkValidity() === true && isValid) {
            const progetto = {
                iniziativa: { iniId: location.state.iniziativa },
                //progTitolo: titolo,
                partecipante: props.partecipante,
                progDataInvio: dayjs().format("YYYY-MM-DD"),
                progStato: statiProgetto.InValidazione,
                progEmail: email,
                progFlConsensoPrivacy: consensoPrivacy,
                progDescrizione1: descrizione1,
                progDescrizione2: descrizione2,
                progDescrizione3: descrizione3
            };
    
            const formData = new FormData();
            formData.append("progetto", JSON.stringify(progetto));
    
            ProgettoService.creaProgetto(formData)
                .then(() => {
                    setMostraModaleOk(true);
                })
                .catch(() => {
                    setMessaggioAlert("Impossibile inviare il progetto");
                });
        } else {
            if (!isValid) {
                setError("L'email inserita non è valida.");
            }
            setMessaggioAlert("Compilare i campi obbligatori. Impossibile inviare il progetto.");
        }
    };
    

    useEffect(() => {
        let opt = []
        opt = props.comune.impostazioniComune && props.comune.impostazioniComune.imcCategorieProgetti.categorie_progetti.map((el, i) => {
            return { "name": el, "id": i }
        })
        setOptions(opt)
    }, [props.comune.impostazioniComune])

    //dismiss alert
    useEffect(() => {
        setTimeout(() => {
            setMessaggioAlert("");
        }, 3000);
    }, [messaggioAlert]);

    const handleRemove = (_, removedItem) => {
        setSelectedOptions(old => old.filter(el => el.id !== removedItem.id))
    }

    const handleSelect = (_, selectedItem) => {
        setSelectedOptions(old => [...old, selectedItem])
        select.searchWrapper.current.classList.remove("invalid")
    }

    const handleUpload = (event) => {
        event.preventDefault()
        inputFile?.click();
    };

    const handleDisplayFileDetails = async () => {
        if (inputFile?.files && inputFile.files[0]
            && inputFile.files[0].size <= max_dimensione_files
            && acceptedExtensions.includes(inputFile.files[0].type)) {
            setUploadedFilesMetadata(old => [...old, inputFile.files[0]])
        } else {
            //if (uploadedFilesMetadata.length >= 5) setMessaggioAlert("Non puoi allegare più di cinque allegati.")
            if (uploadedFilesMetadata.filter(file => file.name === inputFile.files[0].name).length > 1) setMessaggioAlert("Non puoi caricare file con lo stesso nome.")
            else if (!acceptedExtensions.includes(inputFile.files[0].type)) setMessaggioAlert("Il formato del file scelto non è accettabile.")
                else if (inputFile.files[0].size > max_dimensione_files) 
                    setMessaggioAlert("Il file scelto è troppo grande. Riprova.");        }
    }

    const deleteFile = (name) => {
        setUploadedFilesMetadata(old => old.filter(el => el.name !== name))
    }

    if (props.comune && props.comune.impostazioniComune && props.partecipante) {
        if (props.comune.impostazioniComune.imcModuloIniziativeAttivo) {
            if (props.partecipante.parId && props.partecipante.ruolo) {
                return (
                    <>
                        <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
                        <h1 className="title mt-4 text-center dark">Crea il tuo progetto</h1>

                        <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "90vh" }}>
                            <Card.Header>
                                <p className="mt-2 mb-0">Compila il modulo seguente con le informazioni richieste per inviare il tuo progetto.</p>
                                <p className="italic text-muted">I campi contrassegnati con un asterisco (*) sono obbligatori.</p>
                            </Card.Header>
                            <Card.Body className="p-md-5 p-2">
                                <Form noValidate validated={validated} onSubmit={[602, 202,302].includes(location.state.iniziativa) && props.comune.comId== 2 && props.comune.comTitoloUfficiale == "Modena" ? handleSubmitNuovoTipoProgetto : handleSubmit
                                   } ref={el => ref = el} >

                                    {/* CHI */}
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Chi propone: </strong>
                                        </Form.Label>
                                        <Form.Control readOnly
                                            value={props.partecipante.datiPartecipante.dparNome + " " + props.partecipante.datiPartecipante.dparCognome}
                                        />
                                    </Form.Group>

                                    {
                                       [602, 202, 302].includes(location.state.iniziativa) && props.comune.comId== 2 && props.comune.comTitoloUfficiale == "Modena" ? 
                                        (<>
                                         </>) : (<>
                                            <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Ente o Organizzazione rappresentata </strong>
                                        </Form.Label>
                                        <Form.Control placeholder="In caso di rappresentanti, indicare il soggetto, ente o organizzazione"
                                            value={associazione}
                                            onChange={(ev) => { setAssociazione(ev.target.value) }} />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Ci sono altri enti, soggetti o persone che presentano la proposta?</strong>
                                        </Form.Label>
                                        <Form.Control placeholder="Inserire il nome. In caso di singoli cittadini, inserire nome e cognome."
                                            value={copresentatori}
                                            onChange={(ev) => { setCopresentatori(ev.target.value) }} />
                                    </Form.Group>

                                   
                                    <Form.Group className="mb-4 mt-4">
                                        <Form.Label>
                                            <strong>Dove si trova la proposta? * </strong>
                                            <span className="text-muted smallText"></span>
                                        </Form.Label>

                                        <Form.Control required as="select" type="select" className="mb-4"
                                            value={area}
                                            onChange={(ev) => { setArea(ev.target.value) }}>
                                            <option value="" disabled hidden>Selezionare il quartiere-rione tra quelli nella lista</option>
                                            {props.comune && props.comune.impostazioniComune &&
                                                props.comune.impostazioniComune.imcAreeUrbane.aree_urbane.map((el, index) => (
                                                    <option value={index} key={index}>{el}</option>
                                                ))}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Indirizzo </strong>
                                            <span className="text-muted smallText">(Per una maggiore precisione, puoi trascinare il segnaposto sulla mappa)</span>
                                        </Form.Label>
                                        <MappaEIndirizzi
                                            posizione={posizione}
                                            setPosizione={setPosizione}
                                            indirizzo={indirizzo}
                                            setIndirizzo={setIndirizzo}
                                            isRequired={false}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Quali di questi aspetti interessano la proposta? * </strong>
                                        </Form.Label>
                                        <Multiselect required as="option" type="select"
                                            ref={el => select = el}
                                            options={options}
                                            selectedValues={selectedOptions}
                                            onSelect={handleSelect}
                                            onRemove={handleRemove}
                                            placeholder="Seleziona le categorie"
                                            displayValue="name"
                                            emptyRecordMsg="Nessuna opzione corrispondente"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Titolo della proposta * </strong>
                                            <span className="text-muted  d-block italic">Inserire un titolo capace di descrivere in poche parole la proposta (es: "Trasformare gli ingressi delle scuole in piazze per il quartiere", "Un nuovo parco per il quartiere")</span>
                                        </Form.Label>
                                        <Form.Control required placeholder="Inserisci il titolo"
                                            value={titolo}
                                            onChange={(ev) => { setTitolo(ev.target.value) }}
                                        />
                                    </Form.Group></>)
                                    }
                                    

                                    

                                    { [602, 202,302].includes(location.state.iniziativa) && props.comune.comId== 2 && props.comune.comTitoloUfficiale == "Modena" ? 
                                    (<>
                                    
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Quali sono i bisogni che possono essere soddisfatti dall'utilizzo del bene? * </strong>
                                        </Form.Label>
                                        <Form.Control required as="textarea" rows={5}
                                            placeholder="Descrivere quali sono i bisogni che possono essere soddisfatti dall'utilizzo del bene"
                                            value={descrizione1}
                                            maxLength={max_char_descrizione}
                                            onChange={(ev) => { setDescrizione1(ev.target.value) }} />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Quali sono i beneficiari della proposta? * </strong>
                                        </Form.Label>
                                        <Form.Control required as="textarea" rows={5}
                                            placeholder="(es: I giovani, i commercianti, etc.)"
                                            value={descrizione2}
                                            maxLength={max_char_descrizione}
                                            onChange={(ev) => { setDescrizione2(ev.target.value) }} />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Come immagini che potrebbe essere gestito il bene per assicurare nel tempo la sua funzionalità? * </strong>
                                        </Form.Label>
                                        <Form.Control required as="textarea" rows={5}
                                            placeholder="Descrivi come pensi possa essere gestito il bene nel tempo"
                                            value={descrizione3}
                                            maxLength={max_char_descrizione}
                                            onChange={(ev) => { setDescrizione3(ev.target.value) }} />
                                    </Form.Group>
                                    </>): (<>
                                        <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Quali sono i problemi e i bisogni che la proposta vuole affrontare e che cosa vuole migliorare? * </strong>
                                        </Form.Label>
                                        <Form.Control required as="textarea" rows={5}
                                            placeholder="Descrivere le criticità della zona (es: problemi ambientali, sociali, di accessibilità, casa, sicurezza, etc) e gli obiettivi della proposta"
                                            value={descrizione1}
                                            maxLength={max_char_descrizione}
                                            onChange={(ev) => { setDescrizione1(ev.target.value) }} />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Quali sono i luoghi, gli spazi e i servizi sui quali si concentra la proposta? * </strong>
                                        </Form.Label>
                                        <Form.Control required as="textarea" rows={5}
                                            placeholder="(es: La piazza, la strada, il parco, etc.)"
                                            value={descrizione2}
                                            maxLength={max_char_descrizione}
                                            onChange={(ev) => { setDescrizione2(ev.target.value) }} />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>A chi si rivolge la proposta? * </strong>
                                        </Form.Label>
                                        <Form.Control required as="textarea" rows={5}
                                            placeholder="Descrivere gli abitanti della città che beneficeranno della proposta (es: giovani, anziani, commercianti, famiglie, etc.) e che potrebbero anche prenderne parte."
                                            value={descrizione3}
                                            maxLength={max_char_descrizione}
                                            onChange={(ev) => { setDescrizione3(ev.target.value) }} />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>In breve, quali sono i punti di forza della proposta? * </strong>
                                        </Form.Label>
                                        <Form.Control required as="textarea" rows={5}
                                            placeholder="Inserire una breve sintesi per illustrare i punti di forza della proposta (massimo 3 righe)"
                                            value={descrizione4}
                                            maxLength={max_char_descrizione}
                                            onChange={(ev) => { setDescrizione4(ev.target.value) }} />
                                    </Form.Group>

                                    {/* ALLEGATI */}
                                    <Form.Group className="mb-4">
                                        <Row>
                                            <Col xs={10}>
                                                <Form.Label>
                                                    <strong className="mediumText">Allegati </strong>
                                                    <span className="text-muted italic d-block">Se utili a supportare la descrizione della proposta, inserire qui documenti grafici o immagini</span>
                                                    <span className="text-muted smallText">(dimensione massima consentita 5MB, formati accettati: .doc, .pdf, .txt, .png, .jpg)</span>
                                                </Form.Label>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <div>
                                                    <input
                                                        className="d-none"
                                                        type="file"
                                                        multiple={true}
                                                        ref={el => inputFile = el}
                                                        onChange={handleDisplayFileDetails} />
                                                    <Button
                                                        onClick={handleUpload}>
                                                        <FiUpload className="me-2" size="1em" /> Carica
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>

                                        {uploadedFilesMetadata.length > 0 && uploadedFilesMetadata.map((el, i) => (
                                            <ButtonGroup key={i} className="me-3 mb-3">
                                                <Button variant="outline-primary" > <BsFileEarmarkRichtext size="1.5em" /> {el.name}</Button>
                                                <Button variant="outline-danger" onClick={() => { deleteFile(el.name) }}> <GoTrash size="1.5em" /></Button>
                                            </ButtonGroup>
                                        ))}
                                    </Form.Group>
</>)
            }

                                    {/* CONTATTIO */}
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Contatto e-mail *</strong>
                                            <span className="text-muted italic d-block">Inserire il contatto e-mail di riferimento per future comunicazioni ed eventuali approfondimenti, il dato non sarà pubblico</span>
                                        </Form.Label>
                                        <Form.Control required type="email" placeholder="Inserisci la tua email"
                                            value={email}
                                            onChange={(ev) => { setEmail(ev.target.value) }} />
                                        <div>
                                            {error && <span className="text-danger smallText">{error}</span>}
                                        </div>
                                    </Form.Group>


                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Autorizzazione al consenso sulla privacy *</strong>
                                        </Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox"
                                            required
                                            label={
                                                <>
                                                    <span className="italic d-block">Acconsento al trattamento dei miei dati personali:&nbsp;
                                                        <a href={props.comune.comLinkPrivacy}>
                                                            Informativa resa ai sensi dell'art. 13 del RGPD 2016/679
                                                        </a>
                                                        &nbsp;(Regolamento Generale Protezione Dati)
                                                    </span>
                                                </>
                                            }
                                            checked={consensoPrivacy}
                                            onChange={(ev) => {
                                                setConsensoPrivacy(ev.target.checked);
                                            }} 
                                        />
                                    </Form.Group>


                                    {messaggioAlert !== "" && <Alert variant="danger" >{messaggioAlert}</Alert>}
                                    <div className="d-flex justify-content-between">
                                        <Button variant="outline-dark"
                                            onClick={() => { setMostraModaleAnnulla(true) }}>
                                            Annulla
                                        </Button>
                                        <Button
                                            onClick={() => { setMostraModaleConferma(true) }}>
                                            Invia il progetto
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>

                        <ModalConferma
                            mostra={mostraModaleAnnulla}
                            setMostra={setMostraModaleAnnulla}
                            titolo="Annulla creazione del progetto"
                            messaggio={`Sei sicuro di voler annullare la creazione del progetto? Così facendo perderai tutto quello che hai scritto fino ad ora!`}
                            btnAnnulla="No, desidero continuare"
                            btnConferma="Sì, desidero annullare"
                            mode="danger"
                            azioneConferma={() => { navigate(-1) }}
                            azioneAnnulla={() => { setMostraModaleAnnulla(false) }}
                        />

                        <ModalConferma
                            mostra={mostraModaleConferma}
                            setMostra={setMostraModaleConferma}
                            titolo="Invia il progetto"
                            messaggio="Stai per inviare il progetto che hai appena creato. Non sarà più possibile modificarlo. Dovrà essere approvato dal comune prima di essere pubblicato."
                            btnAnnulla="Continua a modificare"
                            btnConferma="Invia"
                            mode="continua"
                            azioneAnnulla={() => { setMostraModaleConferma(false) }}
                            azioneConferma={() => { if([602, 202, 302].includes(location.state.iniziativa)  && props.comune.comId ==2 && props.comune.comTitoloUfficiale == "Modena"){
                                handleSubmitNuovoTipoProgetto()
                             }else{
                                handleSubmit()
                             } }}
                        />

                        <ModalConferma
                            mostra={mostraModaleOk}
                            setMostra={setMostraModaleOk}
                            titolo="Progetto inviato"
                            messaggio="Il progetto è stato inviato correttamente"
                            bodyAlign="text-center"
                            btnConferma="Torna ai progetti"
                            btnAnnulla=""
                            mode="success"
                            azioneAnnulla={() => { }}
                            azioneConferma={() => { navigate(-1) }}
                        />
                    </>
                )
            } else {
                return (
                    <>
                        <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
                        <h1 className="title mt-4 text-center dark">Crea il tuo progetto</h1>
                        <Card className="me-5 mt-2 ms-5 mb-5" style={{ minHeight: "90vh" }}>
                            <Card.Header></Card.Header>
                            <Card.Body className="p-5 text-center">
                                <h2>
                                    <FiAlertTriangle className="me-2 text-warning" />
                                    Attenzione
                                </h2>
                                <br />
                                Per procedere con la creazione di un nuovo progetto devi prima effettuare il login!
                                <br /><br />
                                <Button variant="primary" onClick={goToLogin}>
                                    Accedi alla pagina di Login
                                </Button>
                            </Card.Body>
                        </Card>
                    </>
                )
            }
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
}