import { Badge, Card, Row, Col, Button, Spinner } from 'react-bootstrap'
import CartaFase from './CartaFase'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import dayjs from "dayjs";
import { firmaPetizione } from "../../../utilities/icone";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import ModalPermessi from '../../Utilities/ModalPermessi';
import { statiBilancio, statiProposta } from '../../Utilities/statiElementEnum';
import CartaPropostaVincitrice from './CartaPropostaVincitrice';

export default function InformazioniBilancio(props) {
    const navigate = useNavigate();

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);
    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();
    const [disableButton, setDisableButton] = useState()

    useEffect(() => {
            
           console.log("props.bilancio.fasi", props.bilancio.fasi)
            //trovo la fase corrente
            if (props.bilancio.fasi && props.bilancio.fasi.length !== 0) {
                let faseCorrente = props.bilancio.fasi.filter(fase => dayjs().isBetween(fase.fasDataInizio, fase.fasDataFine, null, []))[0]
                console.log("faseCorrente", faseCorrente)
                setDisableButton(faseCorrente && !faseCorrente.fasFlInvioProposte)
            }else{
                setDisableButton(true)
            }
    
        }, [props]);
// eslint-disable-next-line
    const breakPoints = [
        { width: 1, itemsToShow: 1 }
    ];

    const canCreate = () => {
        // 1. non loggato => vai a login
        // 2. no permessi => avviso
        // 3. tutto ok
        if (props.partecipante && props.partecipante.parId) {
            if (props.partecipante.ruolo && props.partecipante.ruolo.ruoFlPubblicatoreProposte) {
                //caso 3
                navigate('/crea-proposta', { state: { bilancio: props.bilancio } })
            } else {
                // caso 2
                setTitolo("Attenzione: operazione illecita")
                setMessaggio("Attualmente non possiedi i permessi per creare una proposta. L'operazione è bloccata!")
                setMode("danger")
            }
        } else {
            // caso 1
            setTitolo("Attenzione")
            setMessaggio("Per procedere con la creazione di una nuova proposta devi prima effettuare il login!")
            setMode("warning")
        }
        setMostraModalPermessi(true)
    }

    return (
        <>
            {props.loadingBil ?
                <div className="bg-light pt-3 align-items-center d-flex justify-content-center" style={{ minHeight: "45vh" }}>
                    <Spinner animation="border" role="status" />
                </div>
            :
                <div className="bg-light text-center pt-3"
                    style={{
                        backgroundImage: `url(data:image/png;base64,${props.bilancio && props.bilancio.bilBanner})`,
                        minHeight: "45vh",
                        backgroundPosition: "center",
                        backgroundSize: "cover"
                    }}
                />
            }

            <div className='mt-4'>
                <h5 className='d-inline me-2'><Badge bg="primary">Edizione {props.bilancio.bilEdizione}</Badge></h5>
                <h5 className='d-inline me-2'><Badge bg="primary">{props.bilancio.bilStato}</Badge></h5>
                <h5 className='d-inline'><Badge bg="primary">{props.bilancio.proposte && props.bilancio.proposte.length} proposte</Badge></h5>
            </div>

            <Row className='mb-5'>
                <Col className='mt-4 mb-4' xs={12} md={8}>
                    <Card>
                        <Card.Header className="mediumText"><h2 className="title2 text-start mb-3">{props.bilancio.bilTitolo}</h2></Card.Header>
                        <Card.Body>
                            {props.bilancio.bilDescrizione && props.bilancio.bilDescrizione.split("\n").map((val, index) => (
                                <span key={index}>{val} <br /></span>
                            ))}
                        </Card.Body>
                    </Card>

                    {props.bilancio.proposte && props.bilancio.proposte.filter(el => el.proStato === statiProposta.Vincitrice).length > 0 &&
                        <>
                            <h2 className="title2 text-start mb-3">Le proposte vincitrici:</h2>
                            {/* <Carousel className="grabbable" breakPoints={breakPoints}  > */}
                            <Row>
                                {
                                    props.bilancio.proposte
                                    .filter(el => el.proStato === statiProposta.Vincitrice)
                                    .sort((b, a) => { return (a.proVotiOffline + a.votiProposta.length) - (b.proVotiOffline + b.votiProposta.length) })
                                    .map((elemento, index) => (    
                                        <Col  xs={12} lg={6}  key={index}>
                                        <CartaPropostaVincitrice
                                        bilancio={props.bilancio.bilId}
                                        partecipante={props.partecipante}
                                        vota={props.bilancio.fasi.filter(fase => dayjs().isBetween(fase.fasDataInizio, fase.fasDataFine, null, []))[0] &&
                                            props.bilancio.fasi.filter(fase => dayjs().isBetween(fase.fasDataInizio, fase.fasDataFine, null, []))[0].fasFlVotazioni}
                                            proposta={elemento} />
                                            </Col>
                                        ))
                                    }
                                    </Row>
                            {/* </Carousel> */}
                        </>
                    }
                    {props.bilancio.bilStato === statiBilancio.InCorso &&
                        <Card border="primary" className='text-center mt-4 p-3 '>
                            <h4 className='title2 mt-0'>Cosa aspetti?</h4>
                            <Button className="w-50 mx-auto py-2"  disabled={disableButton}
                                onClick={canCreate}>
                                Invia la tua proposta!
                                <span className="m-2">{firmaPetizione}</span>
                            </Button>
                        </Card>
                    }


                </Col>
                <Col>
                    {props.bilancio.fasi &&
                        props.bilancio.fasi
                            .sort((a, b) => a.fasOrdine - b.fasOrdine)
                            .map((fase, index) => (
                                <div key={index}>
                                    <CartaFase fase={fase} key={index} />
                                    {index !== props.bilancio.fasi.length - 1 &&
                                        <HiOutlineDotsVertical key={fase.fasOrdine} className='text-center w-100 text-muted' size="2em" />
                                    }
                                </div>
                            ))}
                </Col>
            </Row >
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}
            />
        </>
    )
}