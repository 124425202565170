import { Badge, Button, Card, Col, ProgressBar, Row } from 'react-bootstrap'
import { FiChevronRight as Scopri } from 'react-icons/fi'
import { getPetizioneColor, getReferendumColor } from '../../Utilities/getBorderColor'
import { useNavigate } from 'react-router-dom'
import { useState } from "react";
import ModalPermessi from "../../Utilities/ModalPermessi";
import { getStatusPetizione, getStatusReferendum } from '../../Utilities/getStatus';
import NumberFormat from "react-number-format"
import dayjs from 'dayjs';

export default function CartaSommario(props) {
    const navigate = useNavigate();

    //stati
    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);
    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    //percentuale di completamento da mostrare sulla progress bar
    const getProgressBarValue = () => {
        if (
            props.comune &&
            props.comune.impostazioniComune &&
            props.mode === "petizione" &&
            props.elemento.firmePetizione
        ){
            return (props.elemento.firmePetizione.length + props.elemento.petFirmeOffline) * 100 / props.comune.impostazioniComune.imcSogliaPetizioni
        }

        if (
            props.mode === "referendum" &&
            props.elemento.firmeReferendum
        )
            return (props.elemento.firmeReferendum.length + props.elemento.refFirmeOffline) * 100 / mostraSoglia()
    }

    //funzione che sceglie la giusta soglia da mostrare
    const mostraSoglia = () => {
        if (props.comune && props.comune.impostazioniComune){
            let soglieAttive = props.comune.impostazioniComune.soglie
                    .filter(s => s.sogActive) // Prendo solo le soglie attive
                    .sort((a,b) => a.sogOrdine - b.sogOrdine); //Ordino le soglie per ordine crescente
            
            let statoReferendum = props.elemento.refStato; //Stato attuale del referendum
                    if (
                props.elemento.soglieReferendum &&
                props.elemento.soglieReferendum.length === 0
            ) {
                //il referendum non ha superato alcuna soglia, quindi scrivo la prima
                return soglieAttive.length > 0 ? soglieAttive[0].sogValore : null;
            } else {
                //il referendum ha superato alcune soglie, quindi prendo la prossima, se esiste
                // Prendo l'ultima soglia approvata
            let ultimaSogliaApprovata = props.elemento.soglieReferendum
            .map(s => s.soglia)
            .sort((a, b) => a.sogOrdine - b.sogOrdine) // Ordino per ordine crescente
            .pop(); // Prendo l'ultima soglia approvata
        
            // Se il referendum è concluso o in attesa di ammissione, mostro l'ultima soglia approvata
            if (statoReferendum === "Concluso" || statoReferendum === "Firme raggiunte" || statoReferendum === "Firme non raggiunte" ) {
                return ultimaSogliaApprovata.sogValore;
            }// Altrimenti, cerco la prossima soglia disponibile
        let prossimaSoglia = soglieAttive.find(s => s.sogOrdine === ultimaSogliaApprovata.sogOrdine + 1);

        // Se esiste una prossima soglia, la ritorno, altrimenti ritorno l'ultima approvata
        return prossimaSoglia ? prossimaSoglia.sogValore : ultimaSogliaApprovata.sogValore;
            }
        }
        return null;
    }

    const scopriElemento = () => {
        if (props.mode === "petizione") {
            navigate('/petizione', { state: { petId: props.elemento.petId } })
        } else if (props.mode === "referendum") {
            navigate('/referendum', { state: { refId: props.elemento.refId } })
        }
    }

    const canView = () => {
        // 1. no permesso visua elemento
        //2. non loggato
        //3. con permessi
        if ((props.partecipante && props.partecipante.ruolo
            && props.partecipante.ruolo.ruoFlVisualizzatore) ||
            !(props.partecipante && props.partecipante.parId)) {
            //caso 3
            scopriElemento()
        } else {
            // caso 2
            setTitolo("Attenzione: operazione illecita")
            setMessaggio(`Attualmente non possiedi i permessi per visualizzare ${props.mode === "petizione" ? "la petizione" : "il referendum"}. L'operazione è bloccata!`)
            setMode("danger")
            setMostraModalPermessi(true)
        }
    }

    return (
        <div>

        <Card className="cartaSommarioBorder " style={{height:"300px"}}
            border={props.mode === "petizione" ? getPetizioneColor(props.elemento) : getReferendumColor(props.elemento)}
            >
            <Card.Body className='d-flex flex-column justify-content-between'>
                <div>
                    {props.mode === "petizione" ?
                        //mode petizione
                        <>
                            <p className='text-muted smallcaps ' ></p>
                            <h5 className="borderTitle" onClick={canView}><strong>{props.elemento.petTitolo}</strong></h5>
                        </>
                        :
                        //mode referendum
                        <>
                            <span className='text-muted smallcaps ' >{props.elemento.refTipo}</span>
                            <h5 className="borderTitle" onClick={canView}><strong>{props.elemento.refTitolo}</strong></h5>
                        </>
                    }
                </div>
                <Row className>
                    <Col xs={6} className='pr-0 text-start'>
                        <span>Termine:</span>
                        <br />
                        <strong>
                            {props.mode === "petizione" ?
                                dayjs(props.elemento.petFineRaccoltaFirme).format('DD/MM/YYYY')
                            :
                            dayjs(props.elemento.refFineRaccoltaFirme).format('DD/MM/YYYY')
                            }
                        </strong>
                    </Col>
                    <Col xs={6} className="p-0 d-flex justify-content-end">
                        <Badge pill
                            bg={props.mode === "petizione" ?
                                getPetizioneColor(props.elemento)
                                :
                                getReferendumColor(props.elemento)
                            }
                            className="mt-auto me-2 py-2"
                            style={{ fontWeight: '100', fontSize: '0.80em', whiteSpace: "normal" }}
                            onClick={canView}>
                            {
                                props.mode === "petizione" ?
                                getStatusPetizione(props.elemento, props.comune)
                                :
                                getStatusReferendum(props.elemento, props.comune)
                            }
                        </Badge>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className='text-center px-0 pb-3' >
                <Row className='w-100 p-0 m-0 d-flex'>
                    <Col xs={7} className='pr-0'>
                        {props.mode === "petizione" ?
                            <span >
                                <NumberFormat
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    value={props.elemento.firmePetizione && props.elemento.firmePetizione.length + props.elemento.petFirmeOffline}
                                    />
                                /
                                <NumberFormat
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    value={props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcSogliaPetizioni}
                                    />
                            </span>
                            :
                            //mode referendum
                            <span >
                                <NumberFormat
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    value={props.elemento.firmeReferendum && props.elemento.firmeReferendum.length + props.elemento.refFirmeOffline}
                                    />
                                /
                                <NumberFormat
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    value={mostraSoglia()}
                                    />
                            </span>
                        }
                        <ProgressBar variant="progressBar" now={getProgressBarValue()} />
                    </Col>
                    <Col xs={5} className="p-0 d-flex align-items-baseline">
                        <Button variant="outline-dark" size="sm" className="mt-auto mx-auto"
                            onClick={canView}>
                            Scopri <Scopri />
                        </Button>
                    </Col>
                </Row>
            </Card.Footer>
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}
            />
        </Card >
                </div>
    )
}