import axios from 'axios'

const PARTECIPANTE_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'partecipante'

const headers = { 'Content-Type': 'application/json' }

class PartecipanteService {
    getPartecipante(id) {
        return axios.get(PARTECIPANTE_REST_API_URL + `/${id}`)
    }

    login(dparEmail, dparPassword, comId) {
        return axios.get(PARTECIPANTE_REST_API_URL + `/login`,
            {
                params: {
                    dparEmail: dparEmail,
                    dparPassword: dparPassword,
                    comId: comId
                }
            })
    }

    getNewOtp(parId) {
        return axios.get(PARTECIPANTE_REST_API_URL+ `/getNewOTP/${parId}`, { headers });
      }

    verifyOTP(parId, params){
        return axios.get(PARTECIPANTE_REST_API_URL+`/verifyOTP/${parId}`,{
          params: params, 
          headers: headers
        });
      }

    findByComune(comId) {
        return axios.get(PARTECIPANTE_REST_API_URL + `/comune/${comId}`)
    }

    confermaToken(token) {
        return axios.post(PARTECIPANTE_REST_API_URL + `/confermaToken/${token}`)
    }

    nuovoPartecipante(comId, partecipante, ruoFlAmministratore) {
        return axios.post(PARTECIPANTE_REST_API_URL + `/nuovoPartecipante/${comId}`,
            {
                dparCf: partecipante.dparCf,
                dparCognome: partecipante.dparCognome,
                dparDataNascita: partecipante.dparDataNascita,
                dparEmail: partecipante.dparEmail,
                dparNome: partecipante.dparNome,
                dparPhoneNumber: partecipante.dparPhoneNumber
            }, {
            params: {
                ruoFlAmministratore: ruoFlAmministratore
            }
        }
        )
    }


    findByCf(dparCf) {

        return axios.get(PARTECIPANTE_REST_API_URL + `/findByCf`,
            {
                params: {
                    dparCf: dparCf
                }
            })
    }
    findByTokenAccesso(dparTokenAccesso) {
        return axios.get(PARTECIPANTE_REST_API_URL + `/findByTokenAccesso`,
            {
                params: {
                    token: dparTokenAccesso
                }
            })
    }

    importPartecipanti(comId, partecipantiCSV) {
        //console.log("importPartecipanti")
        return axios.post(PARTECIPANTE_REST_API_URL + `/csv/upload/${comId}`, partecipantiCSV, headers)
    }

    bloccaPartecipante(parId, flag) {
        return axios.post(PARTECIPANTE_REST_API_URL + `/blocca/${parId}`, null,
            {
                params: {
                    "flag": flag
                }
            })
    }
    cambiaPassword(token, pwd, confermaPwd){
        return axios.post(PARTECIPANTE_REST_API_URL+ `/cambiaPassword/${token}`, 
            {
                "password": pwd,
                "confermaPassword": confermaPwd
            })
    }

    aggiornaPartecipante(partecipante) {
        return axios.post(PARTECIPANTE_REST_API_URL + "/aggiornaPartecipante", partecipante, headers)
    }

    // Metodo per richiamare la funzione di anonimizzazione
    anonimizzaPartecipante(parId) {
        return axios.get(PARTECIPANTE_REST_API_URL + `/anonimizza/${parId}`, { headers })
    }
}

const partecipanteServiceInstance = new PartecipanteService()
export default partecipanteServiceInstance;