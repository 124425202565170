import React, { useEffect } from "react";
import { Row, Col, Form, Button, Container, Alert } from "react-bootstrap";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

export default function ApprovazioniReferendum(props) {
    let referendum = props.referendum;
    let soglie = props.comune.impostazioniComune.soglie;
    let soglieReferendum = referendum.soglieReferendum;
    let firmeReferendum = referendum.firmeReferendum.length + referendum.refFirmeOffline;

    dayjs.extend(customParseFormat);

    useEffect(() => {
        setTimeout(() => {
            props.setShowAlert({});
        }, 3000);
    }, [props]);

    const mostraSoglia = (soglia) => {
        let sogliaPrec = soglie.find(value => value.sogActive === true && value.sogOrdine === (soglia.sogOrdine - 1));

        // Mostra sempre la prima soglia
        /*if (soglia.sogOrdine === 1) {
            return true;
        }*/

        if (sogliaPrec) {
            if (
                firmeReferendum < sogliaPrec.sogValore ||
                !soglieReferendum.some(value => value.soglia.sogId === sogliaPrec.sogId)
            ) {
                return false;
            }
        }

        return true;
    };

    const checkDataApprovazione = () => {
        if(
            dayjs(props.dataApprovazione, 'YYYY-MM-DD', true).isValid() === false ||
            dayjs(props.dataApprovazione).diff(dayjs(), "day") < 0
        ){
            return false
        }else{
            return true
        }
    };

    const checkSogliaNonApprovata = (soglia) => {
        return !soglieReferendum.some(el => el.soglia.sogId === soglia.sogId);
    };

    const preparaApprovaSoglia = (soglia) => {
        if (props.enteApprovazione.length < 1)
            props.setShowAlert({ message: "Specificare l'ente che intende ammettere il referendum", variant: "danger" });
        else if (!checkDataApprovazione())
            props.setShowAlert({ message: "Specificare una data valida a partire da oggi", variant: "danger" });
        else if (!checkSogliaNonApprovata(soglia))
            props.setShowAlert({ message: "La soglia è già stata approvata", variant: "danger" });
        else {
            props.setSogliaToApprove(soglia);
            props.setMostraModaleOperazione(true);
            props.setParolaChiave("Ammetti soglia");
            props.setTitolo("Ammetti il referendum");
            props.setMessaggio("Sei sicuro di voler ammettere il referendum per questa soglia? L'operazione è irreversibile!");
        }
    };

    const preparaRifiutaSoglia = () => {
        props.setMostraModaleOperazione(true);
        props.setParolaChiave("Rifiuta ammissione");
        props.setTitolo("Rifiuta l'ammissione del referendum");
        props.setMessaggio("Sei sicuro di voler rifiutare l'ammissione del referendum per questa soglia? L'operazione è irreversibile!");
    };

    return (
        <>
            <Row>
                <h5 className="mt-4 underline">
                    <strong>Ammissioni</strong>
                </h5>
            </Row>

            {soglie
    .sort((a, b) => a.sogOrdine - b.sogOrdine)
    .map((soglia, index) => {
        const approvazioni = soglieReferendum.filter(el => el.soglia.sogId === soglia.sogId);
        const èStataApprovata = approvazioni.length > 0;

        const ultimaSogliaApprovata = soglieReferendum.length > 0
            ? Math.max(...soglieReferendum.map(el => el.soglia.sogOrdine))
            : 0;

        const primaSogliaNonApprovata = soglie
            .filter(s => s.sogOrdine > ultimaSogliaApprovata && s.sogActive)
            .sort((a, b) => a.sogOrdine - b.sogOrdine)[0];

        return (
            <React.Fragment key={index}>
                {/* Se la soglia è già stata approvata, mostra SOLO le informazioni relative all'approvazione */}
                {èStataApprovata && approvazioni.length > 0 ? (
                    approvazioni.map((sogliaReferendum, idx) => (
                        <Row key={idx} className="justify-content-md-center align-items-center mb-1"> 
                            <Col md={6} className="mb-0 pb-0">
                                <Form.Label className="mb-0 pb-0">
                                    <strong>
                                        Ammesso da {sogliaReferendum.sorEnteApprovante} alle{" "}
                                        <NumberFormat
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            value={soglia.sogValore}
                                        />{" "}
                                        firme:
                                    </strong>
                                </Form.Label>
                            </Col>
                            <Col md={2} className="text-right pb-0 mb-0">
                                {dayjs(sogliaReferendum.sorData).format("DD/MM/YYYY")}
                            </Col>
                        </Row>
                    ))
                ) : (
                    /* Se questa è la prima soglia non approvata, mostra il modulo */
                    soglia.sogId === primaSogliaNonApprovata?.sogId && referendum.refStato !== "Firme raggiunte" && (
                        <Row className="justify-content-md-center align-items-center mb-1">
                            <Col xs={12} md={6}>
                                <Form.Label className="mb-0 pb-0">
                                    <strong>Firme attuali:</strong>
                                </Form.Label>
                            </Col>
                            <Col xs={12} md={6} className="text-md-right">
                                <NumberFormat
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    value={firmeReferendum}
                                />
                                &nbsp;/&nbsp;
                                <NumberFormat
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    value={soglia.sogValore}
                                />
                            </Col>

                            {firmeReferendum >= soglia.sogValore && (
                                <>
                                    <Row className="justify-content-md-center align-items-center mt-2">
                                        <Col md={5}>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>
                                                        <strong>Ammesso da:</strong>
                                                    </Form.Label>
                                                </Col>
                                                <Col md={8} className="right">
                                                    <Form.Control
                                                        type="text"
                                                        size="sm"
                                                        value={props.enteApprovazione}
                                                        onChange={(ev) => props.setEnteApprovazione(ev.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>
                                                        <strong>In data:</strong>
                                                    </Form.Label>
                                                </Col>
                                                <Col md={8} className="right">
                                                    <Form.Control
                                                        type="date"
                                                        size="sm"
                                                        value={props.dataApprovazione}
                                                        onChange={(ev) => props.setDataApprovazione(ev.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-md-center mt-2"> 
                                        <Col md={4}>
                                            <Button
                                                variant="outline-secondary"
                                                onClick={preparaRifiutaSoglia}
                                                size="sm"
                                            >
                                                Rifiuta
                                            </Button>
                                        </Col>
                                        <Col md={4} className="right">
                                            <Button
                                                variant="primary"
                                                onClick={() => {
                                                    preparaApprovaSoglia(soglia);
                                                }}
                                                size="sm"
                                            >
                                                Ammetti
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Row>
                    )
                )}
            </React.Fragment>
        );
    })}



        </>
    );
}
