import { Card, Form, Row, Col, Button, Alert, ButtonGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import ModalConferma from "../../Utilities/ModalConferma";
import { FiUpload } from 'react-icons/fi'
import Multiselect from 'multiselect-react-dropdown';
import { BsFileEarmarkRichtext } from "react-icons/bs"
import { GoTrash } from "react-icons/go"
import PropostaService from "../../../services/PropostaService";
import AllegatoService from "../../../services/AllegatoService";
import { max_char_descrizione, max_char_titolo, max_dimensione_files } from "../../Utilities/variables";
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { Navigate } from 'react-router-dom';
import { FiAlertTriangle } from 'react-icons/fi'
import { statiProposta } from "../../Utilities/statiElementEnum";

export default function CreaProposta(props) {

    //riferimento al form (posizione 0), al multiselect (posizione 1), all'input per i file (posizione 2)
    const ref = useRef([]);

    const location = useLocation();

    const acceptedExtensions = ["image/*", "application/pdf", "text/plain", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "image/png", "image/jpg", "image/jpeg"]

    const [titolo, setTitolo] = useState("")
    const [associazione, setAssociazione] = useState("")
    const [area, setArea] = useState("")
    const [descrizione, setDescrizione] = useState("")
    const [options, setOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])


    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("");

    //stati per i modali di conferma
    const [mostraModaleAnnulla, setMostraModaleAnnulla] = useState(false);
    const [mostraModaleConferma, setMostraModaleConferma] = useState(false);
    const [mostraModaleOk, setMostraModaleOk] = useState(false)

    //stato per mostrare alert di errore
    const [messaggioAlert, setMessaggioAlert] = useState("")

    //stato per i file caricati, metadata e arrayBuffer
    const [uploadedFilesMetadata, setUploadedFilesMetadata] = useState([])

    //controllo per email
    const [error, setError] = useState(null);

    //nuovo flag consenso privacy
    const [consensoPrivacy, setConsensoPrivacy] = useState(false)


    function isValidEmail() {
        return /\S+@\S+\.\S+/.test(email);
    }

    const navigate = useNavigate();
    const goToLogin = () => {
        navigate("/login", { state: { prevPath: location.pathname } })
    }

    const handleSubmit = () => {
        const form = ref.current[0]
        setValidated(true);
        setMostraModaleConferma(false)

        const multiselect = ref.current[1]
       // multiselect.searchWrapper.current.classList.remove("invalid")
       multiselect.searchWrapper.current.classList.remove("invalid", "border", "border-success")

        let isValid = true;

        if(!form.checkValidity()){
            isValid = false;
        }

        if(!isValidEmail()){
            setError("L'email inserita non è valida");
            isValid = false;
        }else{
            setError(null)
        }

        if(multiselect.getSelectedItemsCount() === 0){
            multiselect.searchWrapper.current.classList.add("invalid");
            isValid = false;
        }

        if(multiselect.getSelectedItemsCount() !== 0 ){
            multiselect.searchWrapper.current.classList.add("border")
            multiselect.searchWrapper.current.classList.add("border-success")
        }
        
        if(!isValid){
            setMessaggioAlert("Compilare i campo obbligatori. Impossibile inviare la petizione. ")
            return;
        }
        let proposta = {
            proArea: props.comune.impostazioniComune.imcAreeUrbane.aree_urbane[area],
            proCategorie: { categorie: selectedOptions.map(el => el.name) },
            proDataInvio: dayjs().format("YYYY-MM-DD"),
            proDescrizione: descrizione,
            proStato: statiProposta.InValidazione,
            proTitolo: titolo,
            proAssociazione: associazione,
            proVotiOffline: 0,
            votiProposta: [],
            bilancioPartecipativo: location.state.bilancio,
            partecipante: props.partecipante,
            proEmail: email,
            proFlConsensoPrivacy: consensoPrivacy,

        }
        PropostaService.saveProposta(proposta)
            .then((res) => {
                //res è la proposta appena inserita
                var formData = new FormData()
                formData.append("form", JSON.stringify({ proId: res.data.proId }))
                uploadedFilesMetadata.forEach(el => {
                    formData.append("file", el)
                })
                return AllegatoService.salvaAllegati(formData)
            }).then(() => {
                setMostraModaleOk(true)
            })
            .catch((err) => { setMessaggioAlert("Impossibile inviare la proposta.") 

            })

        /*if (form.checkValidity() === true && multiselect.getSelectedItemsCount() !== 0 && isValidEmail()) {
            let proposta = {
                proArea: props.comune.impostazioniComune.imcAreeUrbane.aree_urbane[area],
                proCategorie: { categorie: selectedOptions.map(el => el.name) },
                proDataInvio: dayjs().format("YYYY-MM-DD"),
                proDescrizione: descrizione,
                proStato: statiProposta.InValidazione,
                proTitolo: titolo,
                proAssociazione: associazione,
                proVotiOffline: 0,
                votiProposta: [],
                bilancioPartecipativo: location.state.bilancio,
                partecipante: props.partecipante,
                proEmail: email,
                proFlConsensoPrivacy: consensoPrivacy,

            }
            PropostaService.saveProposta(proposta)
                .then((res) => {
                    //res è la proposta appena inserita
                    var formData = new FormData()
                    formData.append("form", JSON.stringify({ proId: res.data.proId }))
                    uploadedFilesMetadata.forEach(el => {
                        formData.append("file", el)
                    })
                    return AllegatoService.salvaAllegati(formData)
                }).then(() => {
                    setMostraModaleOk(true)
                })
                .catch((err) => { setMessaggioAlert("Impossibile inviare la proposta.") })
        } else if(!isValidEmail()) {
            //multiselect.searchWrapper.current.classList.add("invalid")
            //TODO: non mettere sempre l'errore sulla email
            setError("L'email inserita non è valida.")
        }else {
            multiselect.searchWrapper.current.classList.add("invalid")
            setMessaggioAlert("Compilare i campi obbligatori. Impossibile inviare la petizione.")

        }*/
    }

    useEffect(() => {
        let opt = []
        opt = props.comune.impostazioniComune && props.comune.impostazioniComune.imcCategorieProposte.categorie_proposte.map((el, i) => {
            return { "name": el, "id": i }
        })
        setOptions(opt)
    }, [props.comune.impostazioniComune])

    //dismiss alert
    useEffect(() => {
        setTimeout(() => {
            setMessaggioAlert("");
        }, 3000);
    }, [messaggioAlert]);

    const handleRemove = (_, removedItem) => {
        setSelectedOptions(old => old.filter(el => el.id !== removedItem.id))
    }

    const handleSelect = (_, selectedItem) => {
        setSelectedOptions(old => [...old, selectedItem])
    }

    const handleUpload = (event) => {
        event.preventDefault()
        ref.current[2]?.click();
    };

    const handleDisplayFileDetails = async () => {
        if (ref.current[2]?.files && ref.current[2].files[0]
            && ref.current[2].files[0].size <= max_dimensione_files
            && acceptedExtensions.includes(ref.current[2].files[0].type)
            & uploadedFilesMetadata.length < 5) {
            setUploadedFilesMetadata(old => [...old, ref.current[2].files[0]])
        } else {
            if (uploadedFilesMetadata.length >= 5) setMessaggioAlert("Non puoi allegare più di cinque allegati.")
            else if (uploadedFilesMetadata.filter(file => file.name === ref.current[2].files[0].name).length > 1) setMessaggioAlert("Non puoi caricare file con lo stesso nome.")
            else if (!acceptedExtensions.includes(ref.current[2].files[0].type)) setMessaggioAlert("Il formato del file scelto non è accettabile.")
            else if (ref.current[2].files[0].size > max_dimensione_files) setMessaggioAlert("Il file scelto è troppo grande. Riprova.")
        }
    }

    const deleteFile = (name) => {
        setUploadedFilesMetadata(old => old.filter(el => el.name !== name))
    }

    if (props.comune && props.comune.impostazioniComune && props.partecipante) {
        if (props.comune.impostazioniComune.imcModuloBilanciAttivo) {
            if (props.partecipante.parId && props.partecipante.ruolo) {
                return (
                    <>
                        <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
                        <h1 className="title mt-4 text-center dark">Crea la tua proposta</h1>

                        <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "90vh" }}>
                            <Card.Header>
                                <p className="mt-2 mb-0">Compila il modulo seguente con le informazioni richieste per inviare la tua proposta.</p>
                                <p className="italic text-muted">I campi contrassegnati con un asterisco (*) sono obbligatori.</p>
                            </Card.Header>
                            <Card.Body className="p-md-5 p-2">
                                <Form noValidate validated={validated} onSubmit={handleSubmit} ref={el => ref.current[0] = el} >
                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Titolo * </strong>
                                            <span className="text-muted smallText">(massimo: {max_char_titolo} caratteri)</span>
                                        </Form.Label>
                                        <Form.Control required placeholder="Inserisci il titolo"
                                            value={titolo}
                                            maxLength={max_char_titolo}
                                            onChange={(ev) => { setTitolo(ev.target.value) }}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Associazione, Comitato </strong>
                                            <span className="text-muted smallText">(massimo: {max_char_titolo} caratteri)</span>
                                        </Form.Label>
                                        <Form.Control placeholder="Inserisci il nome dell'associazione o comitato"
                                            value={associazione}
                                            maxLength={max_char_titolo}
                                            onChange={(ev) => { setAssociazione(ev.target.value) }} />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Area Urbana di riferimento * </strong>
                                        </Form.Label>

                                        <Form.Control required as="select" type="select" className="mb-4"
                                            value={area}
                                            onChange={(ev) => { setArea(ev.target.value) }}>
                                            <option value="" disabled hidden>Seleziona l'area urbana di riferimento</option>
                                            {props.comune && props.comune.impostazioniComune &&
                                                props.comune.impostazioniComune.imcAreeUrbane.aree_urbane.map((el, index) => (
                                                    <option value={index} key={index}>{el}</option>
                                                ))}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Categorie * </strong>
                                        </Form.Label>
                                        <Multiselect required as="option" type="select"
                                            ref={el => ref.current[1] = el}
                                            options={options}
                                            selectedValues={selectedOptions}
                                            onSelect={handleSelect}
                                            onRemove={handleRemove}
                                            placeholder="Seleziona le categorie"
                                            displayValue="name"
                                            emptyRecordMsg="Nessuna opzione corrispondente"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Col xs={8}>
                                            <Form.Label>
                                                <OverlayTrigger placement="right" overlay={
                                                    <Popover id="popover-contained" >
                                                        <Popover.Header as="h3" >Aiuto:</Popover.Header>
                                                        <Popover.Body>
                                                            Esporre, nella maniera più precisa e puntuale possibile, il contenuto della proposta evidenziandone i punti salienti utili all’identificazione della problematica per la quale si richiede l’intervento dell’Amministrazione ovvero di eventuali proposte regolamentari.
                                                            E’ possibile corredare la proposta con atti, documenti e foto di supporto.
                                                        </Popover.Body>
                                                    </Popover>
                                                }>
                                                    <strong>
                                                        <strong>Descrizione * </strong>
                                                        <span className="text-muted smallText">(massimo: {max_char_descrizione} caratteri)</span>
                                                        <AiOutlineQuestionCircle className="me-2 ms-2 mb-1" style={{ fontSize: "20px" }} />
                                                    </strong>
                                                </OverlayTrigger>
                                            </Form.Label>
                                        </Col>
                                        <Form.Control required as="textarea" placeholder="Inserisci la descrizione della proposta" rows={10}
                                            value={descrizione}
                                            maxLength={max_char_descrizione}
                                            onChange={(ev) => { setDescrizione(ev.target.value) }} />
                                    </Form.Group>


                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Email di contatto * </strong>
                                            <span className="text-muted smallText">(l'email che inserirai sarà utilizzata dal Comune per contattarti se necessario)</span>
                                        </Form.Label>
                                        <Form.Control required type="email" placeholder="Inserisci la tua email"
                                            value={email}
                                            onChange={(ev) => { setEmail(ev.target.value) }} />
                                        <div>
                                            {error && <span className="text-danger smallText">{error}</span>}
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Row>
                                            <Col>
                                                <Form.Label>
                                                    <strong className="mediumText">Allegati </strong>
                                                    <span className="text-muted smallText">(dimensione massima consentita 5MB, formati accettati: .doc, .pdf, .txt, .png, .jpg)</span>
                                                </Form.Label>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <div>
                                                    <input
                                                        className="d-none"
                                                        type="file"
                                                        multiple={true}
                                                        ref={el => ref.current[2] = el}
                                                        onChange={handleDisplayFileDetails} />
                                                    <Button
                                                        onClick={handleUpload}>
                                                        <FiUpload className="me-2" size="1em" /> Carica
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>

                                        {uploadedFilesMetadata.length > 0 && uploadedFilesMetadata.map((el, i) => (
                                            <ButtonGroup key={i} className="me-3 mb-3">
                                                <Button variant="outline-primary" > <BsFileEarmarkRichtext size="1.5em" /> {el.name}</Button>
                                                <Button variant="outline-danger" onClick={() => { deleteFile(el.name) }}> <GoTrash size="1.5em" /></Button>
                                            </ButtonGroup>
                                        ))}
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label>
                                            <strong>Autorizzazione al consenso sulla privacy *</strong>
                                        </Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox"
                                            required
                                            label={
                                                <>
                                                    <span className="italic d-block">Acconsento al trattamento dei miei dati personali:&nbsp;
                                                        <a href={props.comune.comLinkPrivacy}>
                                                            Informativa resa ai sensi dell'art. 13 del RGPD 2016/679
                                                        </a>
                                                        &nbsp;(Regolamento Generale Protezione Dati)
                                                    </span>
                                                </>
                                            }
                                            checked={consensoPrivacy}
                                            onChange={(ev) => {
                                                setConsensoPrivacy(ev.target.checked);
                                            }} 
                                        />
                                    </Form.Group>

                                    {messaggioAlert !== "" && <Alert variant="danger" >{messaggioAlert}</Alert>}
                                    <div className="d-flex justify-content-between">
                                        <Button variant="outline-dark"
                                            onClick={() => { setMostraModaleAnnulla(true) }}>
                                            Annulla
                                        </Button>
                                        <Button
                                            onClick={handleSubmit}>
                                            Invia la proposta
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>

                        <ModalConferma
                            mostra={mostraModaleAnnulla}
                            setMostra={setMostraModaleAnnulla}
                            titolo="Annulla creazione della proposta"
                            messaggio={`Sei sicuro di voler annullare la creazione della proposta? Così facendo perderai tutto quello che hai scritto fino ad ora!`}
                            btnAnnulla="No, desidero continuare"
                            btnConferma="Sì, desidero annullare"
                            mode="danger"
                            azioneConferma={() => { navigate(-1) }}
                            azioneAnnulla={() => { setMostraModaleAnnulla(false) }}
                        />

                        <ModalConferma
                            mostra={mostraModaleConferma}
                            setMostra={setMostraModaleConferma}
                            titolo="Invia la proposta"
                            messaggio="Stai per inviare la proposta che hai appena creato. Non sarà più possibile modificarla. Dovrà essere approvata dal comune prima di essere pubblicata."
                            btnAnnulla="Continua a modificare"
                            btnConferma="Invia"
                            mode="continua"
                            azioneAnnulla={() => { setMostraModaleConferma(false) }}
                            azioneConferma={() => { handleSubmit() }}
                        />

                        <ModalConferma
                            mostra={mostraModaleOk}
                            setMostra={setMostraModaleOk}
                            titolo="Proposta inviata"
                            messaggio="La proposta è stata inviata correttamente"
                            bodyAlign="text-center"
                            btnConferma="Torna alle proposte"
                            btnAnnulla=""
                            mode="success"
                            azioneAnnulla={() => { }}
                            azioneConferma={() => { navigate(-1) }}
                        />
                    </>
                )
            } else {
                return (
                    <>
                        <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
                        <h1 className="title mt-4 text-center dark">Crea la tua proposta</h1>
                        <Card className="me-5 mt-2 ms-5 mb-5" style={{ minHeight: "90vh" }}>
                            <Card.Header></Card.Header>
                            <Card.Body className="p-5 text-center">
                                <h2>
                                    <FiAlertTriangle className="me-2 text-warning" />
                                    Attenzione
                                </h2>
                                <br />
                                Per procedere con la creazione di una nuova proposta devi prima effettuare il login!
                                <br /><br />
                                <Button variant="primary" onClick={goToLogin}>
                                    Accedi alla pagina di Login
                                </Button>
                            </Card.Body>
                        </Card>
                    </>
                )
            }
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
}