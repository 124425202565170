import { Button, Card, Col, Row, Collapse, Form, Container, Spinner } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs"
import { useState, useEffect } from "react";
import CartaProposta from "./CartaProposta";
import { useNavigate } from "react-router-dom";
import { RiFilter3Line } from "react-icons/ri"
import Multiselect from 'multiselect-react-dropdown';
import dayjs from "dayjs";
import ModalPermessi from "../../Utilities/ModalPermessi";
import { getStatusOrderProposta } from '../../Utilities/getStatusOrder'
import { statiProposta } from "../../Utilities/statiElementEnum";
import { FaRegCalendarAlt } from "react-icons/fa";
import PaginationBasic from "../Homepage/Pagination";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
export default function EsploraProposte(props) {
    const navigate = useNavigate();

    const [dateRange, setDateRange] = useState([null, null]);

    const [options, setOptions] = useState([])
    const [selectedOptions, setselectedOptions] = useState([])

    const [open, setOpen] = useState(false);

    const [disableButton, setDisableButton] = useState()

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);

    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    let [listaProposte, setListaProposte] = useState([])

    //filtri
    let [statoFilter, setStatoFilter] = useState(false)
    let [categoriaFilter, setCategoriaFilter] = useState([])

    //variabili paginazione progetti
    const [paginaNumero, setPaginaNumero] = useState(0)
    const elementiPerPagina = 5

    const pulisciFiltri = () => {
        setDateRange([null, null])
        setselectedOptions([])
        setCategoriaFilter([])
        setStatoFilter(false)
        setPaginaNumero(0)
    }

    const handleDateRangeChange = (update) => {
        if (update === null) {
            setDateRange([null, null])
        } else {
            setDateRange(update);
        }
        setPaginaNumero(0);
    }

    const getProposte = () => {
        let ret = listaProposte

        ret = ret.filter(proposta => proposta.proStato !== statiProposta.InValidazione && proposta.proStato !== statiProposta.Rifiutata)


        if (categoriaFilter.length > 0) {
            ret = ret.filter((pro) => pro.proCategorie.categorie.some(cat => categoriaFilter.includes(cat)))
        }


        if (dateRange[0]) {
            let startDay = dayjs(dateRange[0])
            ret = ret.filter((pro) => (dayjs(pro.proDataPubblicazione).isAfter(startDay)) || dayjs(pro.proDataPubblicazione).isSame(startDay))
        }

        if (dateRange[1]) {
            let endDay = dayjs(dateRange[1])
            ret = ret.filter((pro) => (dayjs(pro.proDataPubblicazione).isBefore(endDay)) || dayjs(pro.proDataPubblicazione).isSame(endDay))
        }

        if(statoFilter){
            ret = ret.filter((pro) => (pro.proFlAmmessa))
        }

        return ret
    }

    useEffect(() => {
        let opt = props.comune.impostazioniComune && props.comune.impostazioniComune.imcCategorieProposte.categorie_proposte
        opt = [...opt, ...listaProposte.flatMap(p => p.proCategorie.categorie)]
        let categoriePresenti =[...new Set(
            opt
          )].sort()
          .map((el, index)=> ({"name":el, "id":index}))         
        setOptions(categoriePresenti)

        if (props.bilancio.proposte && props.bilancio.proposte.length > 0) {
            setListaProposte(props.bilancio.proposte)
        }

        //trovo la fase corrente
        if (props.bilancio.fasi && props.bilancio.fasi.length !== 0) {
            let faseCorrente = props.bilancio.fasi.filter(fase => dayjs().isBetween(fase.fasDataInizio, fase.fasDataFine, null, []))[0]
            setDisableButton(faseCorrente && !faseCorrente.fasFlInvioProposte)
        }else{
            setDisableButton(true)
        }

    }, [props]);

    const handleSelect = (_, selectedItem) => {
        setCategoriaFilter(old => [...old, selectedItem.name])
        setselectedOptions(old => [...old, selectedItem])
        setPaginaNumero(0)
    }

    const handleRemove = (_, removedItem) => {
        setselectedOptions(old => old.filter(el => el.id !== removedItem.id))
        setCategoriaFilter(categoriaFilter.filter(el => el !== removedItem.name))
        setPaginaNumero(0);
    }

    const getNumeroDiVoti = (proposta) => {
        return proposta.proVotiOffline + proposta.votiProposta.length
    }

    //filtri

    let filterByCategoria = (categoria, selected) => {
        if (selected === true) {
            if (categoriaFilter.filter(categoriaItem => categoriaItem === categoria).length === 0)
                setCategoriaFilter(old => [...old, categoria])
        } else {
            setCategoriaFilter(categoriaFilter.filter(categoriaItem => categoriaItem !== categoria))
        }
        setPaginaNumero(0)
    }

    const IsLogged = () => {
        // 1. non loggato => vai a login
        // 2. no permessi => avviso
        // 3. tutto ok
        if (props.partecipante && props.partecipante.parId) {
            if (props.partecipante.ruolo && props.partecipante.ruolo.ruoFlVisualizzatore && props.partecipante.ruolo.ruoFlPubblicatoreProposte) {
                //caso 3
                navigate('/crea-proposta', { state: { bilancio: props.bilancio } })
            } else {
                // caso 2
                setTitolo("Attenzione: operazione illecita")
                setMessaggio("Attualmente non possiedi i permessi per creare una proposta. L'operazione è bloccata!")
                setMode("danger")
            }
        } else {
            // caso 1
            setTitolo("Attenzione")
            setMessaggio("Per procedere con la creazione di una nuova proposta devi prima effettuare il login!")
            setMode("warning")
        }
        setMostraModalPermessi(true)
    }

    return (
        <>
            <Card style={{ minHeight: "100vh", borderTop: "none", borderRadius: "0px" }}>
                <Card.Body className="p-0 m-0 pb-4">
                    {/* FILTRI A SCHERMO PICCOLO */}
                    <Row className="d-md-none w-100 p-0 m-0">
                        <Col xs={12} sm={6} className="p-1">
                            <Button className="w-100 mb-2" disabled={disableButton} onClick={IsLogged}>
                                Crea la tua proposta
                                <BsPlusLg className="ms-2" />
                            </Button>

                        </Col>
                        <Col xs={12} sm={6} className="p-1">
                            <Button className="w-100" onClick={() => setOpen(!open)} >
                                <RiFilter3Line className="me-2" />
                                Mostra filtri
                            </Button>
                        </Col>
                        <Collapse in={open} className="border-bottom pb-3">
                            <div id="example-collapse-text">
                                <h4 className="mt-4">Filtra per:</h4>
                                <div className="divider my-4" />
                                <Row>
                                    <Col>
                                        <p>Categorie</p>
                                        <Multiselect
                                            options={options}
                                            selectedValues={selectedOptions}
                                            onSelect={handleSelect}
                                            onRemove={handleRemove}
                                            displayValue="name"
                                            onChange={(ev) => {
                                                filterByCategoria(ev.target.value)

                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <p >Stato</p>
                                        <Form.Group controlId="visualizzaVincitrici">
                                            <Form.Check
                                                type="checkbox"
                                                label="Visualizza le proposte vincitrici"
                                                name="Visualizza le proposte vincitrici"
                                                checked={statoFilter}
                                                onChange={(e) => {
                                                    setStatoFilter(e.target.checked);
                                                    setPaginaNumero(0);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p >Data pubblicazione &nbsp;
                                            <span className="small text-muted ">(Da ... a)</span>
                                        </p>
                                        <div>
                                            <DateRangePicker className="w-100"
                                                id="datePickerPetizioni"
                                                onChange={(update) => { handleDateRangeChange(update) }}
                                                value={dateRange}
                                                returnValue="range"
                                                calendarIcon={<FaRegCalendarAlt style={{ height: '1em', width: '1em' }} />}
                                                dayPlaceholder="gg"
                                                monthPlaceholder='mm'
                                                yearPlaceholder='aaaa'
                                                yearAriaLabel="year"
                                                format='dd/MM/y'
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="d-flex justify-content-center mt-4">
                                    <Button size="sm" variant="outline-primary"
                                        onClick={() => { pulisciFiltri() }}>
                                        Pulisci filtri di ricerca
                                    </Button>
                                </div>

                            </div>
                        </Collapse>
                    </Row>

                    <Row className="w-100 m-0 p-0" style={{ minHeight: "100vh" }}>
                        {/* FILTRI A SCHERMO GRANDE*/}
                        <Col md={3} className="bg-light d-none d-md-block">
                            <div className="d-flex justify-content-center">
                                <Button className="my-3" disabled={disableButton}
                                    onClick={IsLogged}>
                                    Crea la tua proposta
                                    <BsPlusLg className="ms-2" />
                                </Button>
                            </div>
                            <h4 className="mt-4">Filtra per:</h4>
                            <div className="divider my-4" />
                            <p>Categorie</p>
                            <Multiselect
                                options={options}
                                selectedValues={selectedOptions}
                                onSelect={handleSelect}
                                onRemove={handleRemove}
                                displayValue="name"
                                onChange={(ev) => {
                                    filterByCategoria(ev.target.value)
                                    setselectedOptions(ev.target.value)
                                }}
                            />
                            <div className="divider my-4" />
                            <p >Stato</p>
                            <Form.Group controlId="visualizzaVincitrici">
                                <Form.Check
                                    type="checkbox"
                                    label="Visualizza le proposte vincitrici"
                                    name="Visualizza le proposte vincitrici"
                                    checked={statoFilter}
                                    onChange={(e) => {
                                        setStatoFilter(e.target.checked);
                                        setPaginaNumero(0);
                                    }}
                                />
                                <div className="divider my-4" />
                                <p >Data pubblicazione &nbsp;
                                    <span className="small text-muted ">(Da ... a)</span>
                                </p>
                                <div>
                                    <DateRangePicker className="w-100"
                                        id="datePickerPetizioni"
                                        onChange={(update) => { handleDateRangeChange(update) }}
                                        value={dateRange}
                                        returnValue="range"
                                        calendarIcon={<FaRegCalendarAlt style={{ height: '1em', width: '1em' }} />}
                                        dayPlaceholder="gg"
                                        monthPlaceholder='mm'
                                        yearPlaceholder='aaaa'
                                        yearAriaLabel="year"
                                        format='dd/MM/y'
                                    />
                                </div>
                            </Form.Group>
                            <div className="divider my-4" />
                            <div className="d-flex justify-content-center">
                                <Button size="sm" variant="outline-primary"
                                    onClick={() => { pulisciFiltri() }}>
                                    Pulisci filtri di ricerca
                                </Button>
                            </div>
                        </Col>

                        {/* TUTTE LE PROPOSTE */}
                        <Col md={9} xs>
                            {props.loadingBil ?
                                <Container className="text-center mt-5">
                                    <Spinner animation="border" role="status" />
                                </Container>
                                :
                                <>
                                    {listaProposte && listaProposte.length > 0 && (
                                        <>
                                            {getProposte()
                                                .filter(proposta => proposta.proStato !== statiProposta.InValidazione && proposta.proStato !== statiProposta.Rifiutata)
                                                .sort(function (a, b) {
                                                    return getStatusOrderProposta(a.proStato) - getStatusOrderProposta(b.proStato)
                                                })
                                                .sort(function (a, b) {
                                                    if (a.proStato === b.proStato && a.proStato === statiProposta.Pubblicata)
                                                        return getNumeroDiVoti(b) - getNumeroDiVoti(a)
                                                    else
                                                        return 0
                                                })
                                                .slice(paginaNumero * elementiPerPagina, (paginaNumero + 1) * elementiPerPagina)
                                                .map((proposta, index) => (
                                                    <CartaProposta key={index}
                                                        proposta={proposta}
                                                        bilancio={props.bilancio.bilId}
                                                        partecipante={props.partecipante}
                                                        vota={props.bilancio.fasi.filter(fase => dayjs().isBetween(fase.fasDataInizio, fase.fasDataFine, null, []))[0] &&
                                                            props.bilancio.fasi.filter(fase => dayjs().isBetween(fase.fasDataInizio, fase.fasDataFine, null, []))[0].fasFlVotazioni} />
                                                ))
                                            }{
                                                getProposte().length > 0 ? (
                                                   <PaginationBasic
                                                activepage={paginaNumero}
                                                setActivePage={setPaginaNumero}
                                                numItems={getProposte().length}
                                                itemsPerPage={elementiPerPagina}
                                            /> 
                                                ) : null
                                            }
                                            
                                        </>)
                                    }
                                </>
                            }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}

            />
        </>
    )
}