import { Button, Card, Col, Row, Form, Collapse, Alert, Container, Spinner } from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { BsPlusLg } from "react-icons/bs"
import { useState, useEffect } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { RiFilter3Line } from "react-icons/ri"
import ModalPermessi from "../../Utilities/ModalPermessi";
import SegnalazioniService from "../../../services/SegnalazioniService";
import CartaSegnalazione from "./CartaSegnalazione";
import { getStatusOrderSegnalazione } from "../../Utilities/getStatusOrder";
import { useLocation } from "react-router-dom";
import { statiSegnalazione } from "../../Utilities/statiElementEnum";
import { FaRegCalendarAlt } from "react-icons/fa";
import PaginationBasic from "../Homepage/Pagination"


import DateRangePicker from '@wojtekmaj/react-daterange-picker';

export default function EsploraSegnalazioni(props) {
    const zoom = 12;
    const location = useLocation()
    const navigate = useNavigate();
    const dayjs = require('dayjs');

    const [dateRange, setDateRange] = useState([null, null]);

    const [selectValue, setSelectValue] = useState(-1)
    const [checkboxes, setCheckboxes] = useState([false, false])

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);

    const [paginaNumero, setPaginaNumero] = useState(0)
    const elementiPerPagina = 5

    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    let [statoFilter, setStatoFilter] = useState([])
    let [categoriaFilter, setCategoriaFilter] = useState("")

    const [open, setOpen] = useState(false);

    let [listaSegnalazioni, setListaSegnalazioni] = useState([])
    let [listaPosizioni, setListaPosizioni] = useState([])
    let [loadingSeg, setLoadingSeg] = useState(false)
    // const [paginaNumero, setPaginaNumero] = useState(0)
    // const petPagina = 5
    // const paginaVisitata = paginaNumero * petPagina

    const IsLogged = () => {
        // 1. non loggato => vai a login
        // 2. no permessi => avviso
        // 3. tutto ok
        if (props.partecipante && props.partecipante.parId) {
            if (props.partecipante.ruolo && props.partecipante.ruolo.ruoFlPubblicatoreSegnalazioni) {
                //caso 3
                navigate("/crea-segnalazione")
            } else {
                // caso 2
                setTitolo("Attenzione: operazione illecita")
                setMessaggio("Attualmente non possiedi i permessi per creare una segnalazione. L'operazione è bloccata!")
                setMode("danger")
            }
        } else {
            // caso 1
            setTitolo("Attenzione")
            setMessaggio("Per procedere con la creazione di una nuova segnalazione devi prima effettuare il login!")
            setMode("warning")
        }
        setMostraModalPermessi(true)
    }

    // filtri
    let filterByStato = (stato, checked) => {
        if (checked === true) {
            if (statoFilter.filter(statoItem => statoItem === stato).length === 0)
                setStatoFilter(old => [...old, stato])
        } else {
            setStatoFilter(statoFilter.filter(statoItem => statoItem !== stato))
        }
        setPaginaNumero(0); // Resetto alla prima pagina

    }

    let filterByCategoria = (categoria) => {
        setCategoriaFilter(categoria)
        setPaginaNumero(0); // Resetto alla prima pagina

    }

    const pulisciFiltri = () => {
        setDateRange([null, null])
        setSelectValue(-1)
        setCheckboxes([false, false, false])
        setStatoFilter([])
        setCategoriaFilter([])
        setPaginaNumero(0); // Resetto alla prima pagina

    }

    useEffect(() => {
        if (props.partecipante.parId) {
            setLoadingSeg(true)
            SegnalazioniService.findByPartecipante(props.partecipante.parId)
                .then(res => {
                    setListaSegnalazioni(res.data)
                    let posizioni = []
                    res.data.forEach(segnalazione => {
                        posizioni.push(segnalazione.posizione)
                    });
                    setListaPosizioni(posizioni)
                })
                .catch(err => {
                    setListaSegnalazioni([])
                    setListaPosizioni([])
                })
                .finally(() => { setLoadingSeg(false) })
        }
        /* else{
            setLoadingSeg(false)
            setTitolo("Attenzione")
            setMessaggio("Per procedere con la visualizzazione delle tue segnalazioni devi prima effettuare il login!")
            setMode("warning")
            setMostraModalPermessi(true)
        } */
    }, [props.partecipante.parId]);


    const handleCheckbox = (index) => {
        let tmp = checkboxes
        tmp[index] = !tmp[index]
        setCheckboxes(tmp)
    }

    const handleDateRangeChange = (update) => {
        if (update === null) {
            setDateRange([null, null])
        } else {
            setDateRange(update);
        }
        setPaginaNumero(0); // Resetto alla prima pagina

    }

    const getSegnalazioni = () => {
        let ret = listaSegnalazioni
            
        if (statoFilter.length > 0) {
            ret = ret.filter(seg => statoFilter.includes(seg.segStato))
        }

        if (categoriaFilter.length>0){
            ret = ret.filter(seg =>  seg.segCategoria === categoriaFilter)
        } 
        if (dateRange[0]) {
            let startDay = dayjs(dateRange[0])
            ret = ret.filter((seg) => (dayjs(seg.segDataPubblicazione).isAfter(startDay)) || dayjs(seg.segDataPubblicazione).isSame(startDay))
        }

        if (dateRange[1]) {
            let endDay = dayjs(dateRange[1])
            ret = ret.filter((seg) => (dayjs(seg.segDataPubblicazione).isBefore(endDay)) || dayjs(seg.segDataPubblicazione).isSame(endDay))
        }
        return ret
    }


    if (props.comune && props.comune.impostazioniComune) {
        if (props.comune.impostazioniComune.imcModuloSegnalazioniAttivo) {
            return (
                <>
                    <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
                    <h1 className="title mt-4 text-center dark">Segnalazioni</h1>
                    <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "100vh" }}>
                        <Card.Body className="p-0 m-0">
                            {/* FILTRI A SCHERMO PICCOLO */}
                            <Row className="d-md-none w-100 p-0 m-0">
                                <Col xs={12} sm={6} className="p-1">
                                    <Button className="w-100 mb-2" onClick={IsLogged}>
                                        Invia la tua segnalazione
                                        <BsPlusLg className="ms-2" />
                                    </Button>
                                </Col>
                                <Col xs={12} sm={6} className="p-1">
                                    <Button className="w-100" onClick={() => setOpen(!open)} >
                                        <RiFilter3Line className="me-2" />
                                        Mostra filtri
                                    </Button>
                                </Col>
                                <Collapse in={open} className="border-bottom pb-3">
                                    <div id="example-collapse-text">
                                        <h4 className="mt-4">Filtra per:</h4>
                                        <div className="divider my-4" />
                                        <Row>
                                            <Col>
                                                <p >Categoria</p>
                                                <Form.Select size="sm" className="mb-4"
                                                    value={selectValue}
                                                    onChange={(ev) => {
                                                        filterByCategoria(ev.target.value);
                                                        setSelectValue(ev.target.value)
                                                    }}>
                                                    <option value="-1" disabled hidden>Seleziona la categoria</option>
                                                    {[...new Set([
                                            ...listaSegnalazioni.map(s => s.segCategoria),
                                            ...(props.comune?.impostazioniComune?.imcCategorieSegnalazioni?.categorie_segnalazioni|| [])
                                        ])]
                                            .sort()
                                           .map((el, index) => (
                                                            <option value={el} key={index}>{el}</option>
                                                        ))}
                                                </Form.Select>
                                            </Col>
                                            <Col>
                                                <p >Stato</p>
                                                <Form.Group>
                                        {Object.values(statiSegnalazione)
                                            .map((stato, index) => (
                                                <Form.Check
                                                key={index}
                                                    type="checkbox"
                                                    label={stato}
                                                    name={stato}
                                                    checked={checkboxes[index]}
                                                    onChange={(e) => {
                                                        filterByStato(e.target.name, e.target.checked)
                                                        handleCheckbox(index)
                                                    }}
                                                />
                                            )
                                            )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <p >Data invio &nbsp;
                                                    <span className="small text-muted ">(Da ... a)</span>
                                                </p>
                                                <div>
                                                    <DateRangePicker className="w-100"
                                                        id="datePickerPetizioni"
                                                        onChange={(update) => { handleDateRangeChange(update) }}
                                                        value={dateRange}
                                                        returnValue="range"
                                                        calendarIcon={<FaRegCalendarAlt style={{ height: '1em', width: '1em' }} />}
                                                        dayPlaceholder="gg"
                                                        monthPlaceholder='mm'
                                                        yearPlaceholder='aaaa'
                                                        yearAriaLabel="year"
                                                        format='dd/MM/y'
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="d-flex justify-content-center mt-4">
                                            <Button size="sm" variant="outline-primary"
                                                onClick={() => {
                                                    pulisciFiltri()
                                                }}>
                                                Pulisci filtri di ricerca
                                            </Button>
                                        </div>

                                    </div>
                                </Collapse>
                            </Row>

                            <Row className="w-100 m-0 p-0" style={{ minHeight: "100vh" }}>
                                {/* FILTRI A SCHERMO GRANDE*/}
                                <Col md={3} className="bg-light d-none d-md-block">
                                    <div className="d-flex justify-content-center">
                                        <Button className="my-3"
                                            onClick={IsLogged}>
                                            Invia la tua segnalazione
                                            <BsPlusLg className="ms-2" />
                                        </Button>
                                    </div>
                                    <h4 className="mt-4">Filtra per:</h4>
                                    <div className="divider my-4" />
                                    <p >Categoria</p>
                                    <Form.Select size="sm" className="mb-4"
                                        value={selectValue}
                                        onChange={(ev) => { filterByCategoria(ev.target.value); setSelectValue(ev.target.value) }}
                                        /*onChange={(ev) => { setSelectValue(ev.target.value) }}*/>
                                        <option value="-1" disabled hidden>Seleziona la categoria</option>
                                        {[...new Set([
                                            ...listaSegnalazioni.map(s => s.segCategoria),
                                            ...(props.comune?.impostazioniComune?.imcCategorieSegnalazioni?.categorie_segnalazioni|| [])
                                        ])]
                                            .sort()
                                           .map((el, index) => (
                                                            <option value={el} key={index}>{el}</option>
                                                        ))}
                                    </Form.Select>
                                    <div className="divider my-4" />
                                    <p >Stato</p>
                                    <Form.Group>
                                        {Object.values(statiSegnalazione)
                                            .map((stato, index) => (
                                                <Form.Check
                                                key={index}
                                                    type="checkbox"
                                                    label={stato}
                                                    name={stato}
                                                    checked={checkboxes[index]}
                                                    onChange={(e) => {
                                                        filterByStato(e.target.name, e.target.checked)
                                                        handleCheckbox(index)
                                                    }}
                                                />
                                            )
                                            )}
                                                </Form.Group>

                                    <div className="divider my-4" />
                                    <p >Data invio &nbsp;
                                                    <span className="small text-muted ">(Da ... a)</span>
                                                </p>
                                                <div>
                                                    <DateRangePicker className="w-100"
                                                        id="datePickerPetizioni"
                                                        onChange={(update) => { handleDateRangeChange(update) }}
                                                        value={dateRange}
                                                        returnValue="range"
                                                        calendarIcon={<FaRegCalendarAlt style={{ height: '1em', width: '1em' }} />}
                                                        dayPlaceholder="gg"
                                                        monthPlaceholder='mm'
                                                        yearPlaceholder='aaaa'
                                                        yearAriaLabel="year"
                                                        format='dd/MM/y'
                                                    />
                                                </div>
                                    <div className="divider my-4" />
                                    <div className="d-flex justify-content-center">
                                        <Button size="sm" variant="outline-primary"
                                            onClick={() => { pulisciFiltri() }}>
                                            Pulisci filtri di ricerca
                                        </Button>
                                    </div>

                                </Col>

                                <Col>
                                {/* MAPPA */}
                                    <MapContainer className="mx-1 mt-1" style={{ height: "30vh" }}
                                        center={props.comune.impostazioniComune && props.comune.impostazioniComune.imcCentroMappa.centro_mappa}
                                         zoom={zoom} scrollWheelZoom={false}>
                                        <TileLayer
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        {listaPosizioni.length > 0 &&
                                            listaPosizioni.map((posizione, index) => (
                                                <Marker
                                                    position={[posizione.posLatitudine, posizione.posLongitudine]}
                                                    key={index}
                                                >
                                                    <Popup minWidth={90}>
                                                        <span>
                                                            {posizione.posNome}
                                                        </span>
                                                    </Popup>
                                                </Marker>
                                            ))}
                                    </MapContainer>


                                    {!props.partecipante.parId && <Alert variant="warning" className="mt-3">Per visionare le tue segnalazioni, <Link to={{ pathname: "/login", state: { prevPath: location.pathname } }}>effettua il login</Link>.</Alert>}
                                    {loadingSeg ?
                                        <Container className="text-center mt-5">
                                            <Spinner animation="border" role="status" />
                                        </Container>
                                    :
                                        <>
                                            {props.partecipante.parId && listaSegnalazioni.length === 0 ?
                                                <Alert variant="warning" className="mt-3">Non hai ancora inserito nessuna segnalazione. Non appena ne avrai pubblicata una, verrà visualizzata qui.</Alert>
                                                :
                                                getSegnalazioni()
                                                    .sort(function (a, b) {
                                                        return getStatusOrderSegnalazione(a.segStato) - getStatusOrderSegnalazione(b.segStato)
                                                    })
                                                    .slice(paginaNumero * elementiPerPagina, (paginaNumero +1) * elementiPerPagina)
                                                    .map((segnalazione, index) => (
                                                        <CartaSegnalazione segnalazione={segnalazione} key={index} />
                                                    ))
                                            }
                                            {
                                                getSegnalazioni().length > 0 ? (
                                                    <PaginationBasic
                                                activepage={paginaNumero}
                                                setActivePage={setPaginaNumero}
                                                numItems={getSegnalazioni().length}
                                                itemsPerPage={elementiPerPagina}
                                            />
                                                ) : null
                                            }
                                            
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <ModalPermessi
                        mostra={mostraModalPermessi}
                        setMostra={setMostraModalPermessi}
                        titolo={titolo}
                        messaggio={messaggio}
                        mode={mode}
                    />
                </>
            )
        } else {
            return (
                <>
                    <Navigate replace to="/pagina-non-trovata" />
                </>
            )
        }
    }
}
