import React, { useState, useEffect } from "react";
import { Row, Col, Form, InputGroup, Button, Container } from "react-bootstrap";
import dayjs from "dayjs";

export default function ImpostazioniSoglie(props) {
  let stepSoglie = props.stepSoglie;
  const [tempSoglie, setTempSoglie] = useState([]);

  useEffect(() => {
    setTempSoglie(props.soglie.filter(s => s.sogActive));
  }, [props.soglie]);

  let editSoglia = (value, sogOrdine) => {
    if (!value) value = 0;

    setTempSoglie(oldSoglie => {
        return oldSoglie.map(soglia => {
            if (soglia.sogOrdine === sogOrdine) {
                return {
                    sogOrdine: soglia.sogOrdine,
                    sogValore: parseInt(value, 10),
                    sogActive: true, 
                    sogDataCreazione: dayjs().format("YYYY-MM-DD"), // Nuovo record
                };
            }
            return soglia;
        });
    });
};


  let increaseSoglie = () => {
    let nuovoOrdine = tempSoglie.length > 0 ? Math.max(...tempSoglie.map(s => s.sogOrdine)) + 1 : 1;
    let nuovoValore = tempSoglie.length > 0 ? tempSoglie[tempSoglie.length - 1].sogValore + stepSoglie : stepSoglie;

    setTempSoglie(oldSoglie => [
      ...oldSoglie,
      {
        sogOrdine: nuovoOrdine,
        sogValore: nuovoValore,
        sogActive: true
      }
    ]);
  };

  let decreaseSoglie = () => {
    setTempSoglie(oldSoglie => {
      if (oldSoglie.length === 0) return oldSoglie;

      let nuoveSoglie = [...oldSoglie];
      let sogliaDaRimuovere = nuoveSoglie.pop(); // Prendo l'ultima soglia

      if (sogliaDaRimuovere.sogId) {
        // Se la soglia è già esistente nel DB, la segno come disattivata
        nuoveSoglie = nuoveSoglie.map(s =>
          s.sogOrdine === sogliaDaRimuovere.sogOrdine
            ? { ...s, sogActive: false, sogDataDisattivazione: dayjs().format("YYYY-MM-DD") }
            : s
        );
      }

      return nuoveSoglie;
    });
  };

  return (
    <>
      <Col>
        <Form.Group as={Row} className="mb-4">
          <Form.Label column>
            <strong>Approvazioni</strong>
          </Form.Label>
        </Form.Group>

        <div className="container">
          <Form.Group as={Row} className="mb-4">
            <Col>
              <Form.Label>Approvazioni richieste</Form.Label>
            </Col>
            <Col className="text-center justify-content-between d-flex">
              <Button variant="outline-primary" onClick={decreaseSoglie} disabled={tempSoglie.length === 0}>
                <strong>-</strong>
              </Button>
              <Form.Label className="my-auto">
                <strong>{tempSoglie.filter(s => s.sogActive).length}</strong>
              </Form.Label>
              <Button variant="outline-primary" onClick={increaseSoglie}>
                <strong>+</strong>
              </Button>
            </Col>
          </Form.Group>

          {tempSoglie.filter(s => s.sogActive).map((soglia, index) => (
            <Form.Group key={index} as={Row} className="mb-4">
              <Col>
                <Form.Label>Al raggiungimento di</Form.Label>
              </Col>
              <Col>
                <InputGroup>
                  <Form.Control
                    value={soglia.sogValore || ""}
                    type="number"
                    className="text-center"
                    step={stepSoglie}
                    onChange={(ev) => {
                      props.svuotaMessaggi();
                      editSoglia(ev.target.value, soglia.sogOrdine);
                    }}
                  />
                </InputGroup>
              </Col>
            </Form.Group>
          ))}
        </div>
      </Col>

      <Container className="d-flex justify-content-end w-90 p-0 pt-5">
        <Button className="mb-5 shadow" onClick={() => props.preparaSalva(tempSoglie)}>
          Aggiorna
        </Button>
      </Container>
    </>
  );
}
